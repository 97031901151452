
import headerTab from '~/components/common/header'
// import footerTab from '~/components/common/footer'
import cookies from '@/utils/cookies'
import { setStore, removeStore, getStore } from '@/utils/storage'
import { downloadlibrary } from '~/api/course.js'
import { gaodeMap } from '~/utils/amap'
import { mapGetters } from 'vuex'
import Theme from '~/utils/theme'
import {
  getUserAgres,
  saveUserAgres
} from "@/api/user";
export default {
  name: 'Default',
  components: {
    headerTab
    // footerTab
  },
  props: {},
  data() {
    return {
      agressDialogVisible: false, // 显示协议对话框
      vxAgreementDisplayrules: 1, // 1 始终弹出 2 同意后不弹出
      service: {}
    }
  },
  head() {
    return {
      title: this.$store.state.clientData.name || '',
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: this.$store.state.webInfo
            ? this.$store.state.webInfo.websiteIco
            : ''
        },
        { rel: 'stylesheet', type: 'text/css', href: '/wangEditor.min.css' },
        { rel: 'stylesheet', type: 'text/css', href: '/tcplayer/tcplayer.min.css'}
      ],
      script: [
        { src: '//lib.baomitu.com/jquery/3.5.1/jquery.min.js' },
        { src: '//live-cdn.baijiayun.com/bplayer/latest/bplayer.js' },
        { src: '//live-cdn.baijiayun.com/bplayer/latest/dep/ffplayer.js' },
        { src: '//player.polyv.net/script/player.js' },
        { src: '//player.polyv.net/script/polyvplayer.min.js' },
        { src: '/tcplayer/libs/hls.min.1.1.7.js'},
        { src: '/tcplayer/tcplayer.v5.1.0.min.js'}
      ]
    }
  },
  computed: {
    ...mapGetters(['webInfo'])
  },
  watch: {
    '$store.state.lang'(val) {
      this.$i18n.locale = val
    }
  },
  created() { },
  mounted() {
    this.$i18n.locale = cookies.getInClient('yqx_web_lang') || 'zh'
    document.addEventListener('click', (e) => {
      // console.log(e.target.getAttribute('data-id'), 111)
      if (e && e.target) {
        if (e && e.target) {
          if (
            e.target.parentNode &&
            e.target.parentNode.className &&
            e.target.parentNode.className.indexOf('file_a') > -1 &&
            e.target.parentNode.getAttribute('data-id')
          ) {
            this.downloadlibrary(e.target.parentNode.getAttribute('data-id'))
            return
          }
          if (
            e.target &&
            e.target.className &&
            e.target.className.indexOf('file_a') > -1 &&
            e.target.getAttribute('data-id')
          ) {
            this.downloadlibrary(e.target.getAttribute('data-id'))
            return
          }
        }
      }
    })
    if (this.webInfo.amapKeyId && this.webInfo.amapKeySecret) {
      gaodeMap(this.webInfo.amapKeyId, this.webInfo.amapKeySecret)
    }

    const tokenInfo = this.$store.state.tokenInfo;

    if (this.$route.path === '/home' && tokenInfo) {
      this.$store.dispatch("GET_WEBINFO", { domain: window.location.host })
      .then((res) => {
        this.service = res;
        this.vxAgreementDisplayrules = !res.vxAgreementDisplayrules ? 1 : res.vxAgreementDisplayrules
        setStore("vxPagecss", res.vxPagecss);
        setStore("vxPagetheme", res.vxPagetheme || '#409EFF');
  
        if (this.service.vxUseragreement) {
          if(this.vxAgreementDisplayrules === 1) {
              this.agressDialogVisible = true;
          } else {
            // 调用接口获取用户是否保存过用户协议 存在不弹出 不存在弹出 
              getUserAgres().then((r)=> {
                console.log('查看是否确认过协议', r)
                if(r && r.data === false) {
                  this.agressDialogVisible = true;
                }
              })            
          }
        }
      });
    }

    var cssStr = getStore('vxPagecss');
    if (cssStr) {
      var style = document.createElement('style');
      style.type = 'text/css';
      style.innerHTML = cssStr;
      document.head.appendChild(style);
    }


    let vxPagetheme = getStore('vxPagetheme');
    document.documentElement.style.setProperty('--el-color-primary', vxPagetheme);
    if (vxPagetheme) {
      const theme = new Theme();
      theme.changeTheme(vxPagetheme, '#409EFF');
    }

  },
  methods: {
    // 拒绝协议后退出登录并清空登录数据
    refuseAgress() {
      saveUserAgres({agreementState: 0}).then(() => {
        this.agressDialogVisible = false;
        setTimeout(() => {
          this.$store.dispatch('REDIRECT_LOGIN').then(res => {
            this.$router.push({ path: '/login' })
          })
        },0)
      });
    },
    // 同意协议调用接口更新用户数据并跳转
    agreeAress() {
      // 调用保存接口
      saveUserAgres({agreementState: 1});
      setTimeout(() => {
        this.agressDialogVisible = false;
      },0)
    },
    downloadlibrary(id) {
      downloadlibrary(id).then((res) => {
        if (res) {
          setStore('is_download_file', true)
          window.open(res, '_blnak')
          setTimeout(() => {
            removeStore('is_download_file')
          }, 2000)
        }
      })
    }
  }
}

export default {
  notData: '暫無數據',
  en: '英語',
  cn: '簡體中文',
  common: '共',
  page: '頁',
  data: '條數據',
  confirm: '確認',
  name: '姓名',
  and: '和',
  pleaseInputName: '請輸入姓名',
  post: '職位',
  pleaseSelect: '請選擇',
  companyName: '企業名稱',
  pleaseInputCompanyName: '請輸入企業名稱',
  entScale: '企業規模',
  address: '企業地址',
  entIndustry: '所屬行業',
  entCity: '所屬城市',
  more: '更多',
  changes: '換一換',
  integral: '學分',
  creditHour: '積分',
  certificateCount: '證書',
  stick: '置頂',
  sheet: '張',
  point: '分',
  hitBooks: '進行學習',
  learnTask: '學習任務',
  testTask: '考試任務',
  assessmentTask: '測試任務',
  coachTask: '輔導任務',
  notStarted: '未開始',
  learning: '學習中',
  haveLearned: '已學完',
  waitTest: '待考試',
  pass: '合格',
  fail: '不合格',
  waitMark: '待閱卷',
  absent: '缺考',
  designate: '指派',
  recommend: '推薦',
  hot: '熱門',
  newest: '最新',
  uploadA: '上傳',
  course: '課程',
  studyCourse: '學習課程',
  learnProject: '學習項目',
  peopleLearn: '學習中',
  myTask: '我的任務',
  trainRecords: '培訓履歴',
  myRecord: '我的記錄',
  trainOperation: '培訓操作',
  myNews: '我的消息',
  setLanguage: '設置語言',
  inviteMembersJoin: '邀請成員加入',
  search: '搜索',
  cancel: '取消',
  searchHistory: '搜索歷史',
  emptyHistory: '清空歷史',
  searchRecommended: '搜索推薦',
  loading: '加載中......',
  scrollBottom: '已經到底部，沒有更多內容了',
  CheckOutMoreComments: '查看更多評論',
  checkMore: '查看更多',
  lineClass: '線上課',
  graphicClass: '圖文課',
  liveClass: '直播課',
  learnState: '學習狀態',
  all: '全部',
  notLearn: '未學習',
  sort: '排序',
  default: '默認',
  latest: '最新',
  hottest: '最熱門',
  comments: '評論',
  giveLike: '點讚',
  congratulation: '恭喜您',
  registerSuccess: '註冊成功',
  jumpAfter: '之後自動跳轉',
  toLogin: '前往登錄 >',
  pleaseSelectCompany: '請選擇要進入的公司',
  addCompany: '新增企業',
  chooseEnterpriseSuccess: '選擇企業成功',
  headPortrait: '頭像',
  phone: '手機',
  changePassword: '修改密碼',
  Logout: '退出登錄',
  unbounded: '未綁定',
  editSuccess: '修改成功',
  ranking: '排名',
  score: '得分',
  realTimeDataUpdate: '數據實時更新',
  takingPictures: '拍照',
  choosePhonePhotoAlbum: '從手機相冊選擇',
  complete: '完成',
  corporateAnnouncements: '企業公告',
  learnMessage: '學習消息',
  read: '已讀',
  unread: '未讀',
  pleaseInputPhone: '請輸入手機號碼',
  password: '密碼',
  pleaseInputPassword: '請輸入密碼',
  code: '驗證碼',
  pleaseInputCode: '請輸入驗證碼',
  getCode: '獲取驗證碼',
  codeLogin: '驗證碼登錄',
  passwordLogin: '密碼登錄',
  forgetPassword: '重置密碼',
  register: '企業註冊',
  useAccountLogin: '使用第三方賬號快速登錄',
  verifyMobileRegistered: '正在驗證手機號是否已註冊....',
  cellPhoneNumberRegistered: '手機號已註冊',
  loginPassword: '登錄密碼',
  checkPassword: '重複密碼',
  pleaseEnterLoginPassword: '請輸入登錄密碼',
  pleaseEnterCheckPassword: '請輸入重複密碼',
  passwordLength: '{label}長度在 {minLength} 到 {maxLength} 個字符',
  passwordRuleCheck: '登錄密碼與重複密碼不一致',
  pleaseInputLoginPassword: '請設置6-16位數字、字母符號組合',
  graded: '評分',
  beginLearn: '開始學習',
  continueLearn: '繼續學習',
  courseware: '教材',
  lecturer: '講師',
  courseIntroduction: '課程介紹',
  courseCatalog: '課程目錄',
  studentsComment: '學員評論',
  watchRecord: '觀看記錄',
  watchCourseware: '資料列表',
  haveThumbUp: '已點讚',
  hasBeenCancelled: '已取消',
  details: '詳情 >',
  introduce: '介紹',
  unfinished: '未完成',
  completed: '已完成',
  overdue: '逾期',
  exam: '考試',
  time: '次',
  totalScore: '總分',
  goal: '得分',
  notAvailable: '暫無',
  totalProgress: '總進度',
  periodValidity: '任務期限',
  qualifiedReward: '合格獎勵',
  certificateQuality: '合格證書',
  learnProcess: '學習進度',
  testSchedule: '考試進度',
  solidExerciseSchedule: '實操進度',
  trainPurpose: '培訓目的',
  completeJobEvaluation: '完成崗位測評',
  eligibilityCriteria: '合格標準',
  credits: '學分',
  have: '有',
  field: '場',
  pleaseSelectPost: '請選擇職務',
  scale: '企業規模',
  pleaseSelectScale: '請選擇企業規模',
  industry: '所屬行業',
  pleaseSelectIndustry: '請選擇所屬行業',
  city: '所屬城市',
  pleaseSelectCity: '請選擇所屬城市',
  synopsis: '簡介',
  browse: '瀏覽',
  playCount: '播放次數',
  allComments: '全部評論',
  pleaseInputComment: '請輸入評論',
  taskDirectory: '任務目錄',
  pleaseApply: '請報名該項目',
  registerNow: '立即報名',
  learnContent: '學習內容',
  originalClass: '原創課',
  tip: '提示',
  deleteComment: '該評論一經刪除，將不可恢復',
  newEmployeeTask: '新員工任務',
  takeTask: '選修任務',
  taskFile: '任務檔案',
  learnFile: '學習檔案',
  certificationFile: '認證檔案',
  learnRecord: '學習記錄',
  certificateRecord: '證書記錄',
  courseRecord: '課程記錄',
  learnProjectRecord: '學習項目記錄',
  companyTitle: '請填寫真實信息，讓您的同事更快捷地找到您。',
  registerSuccessTip: '我已閱讀並同意',
  userLog: '《服務協議與隱私政策》',
  loginSuccessUseLog: '我已閱讀並同意',
  pleaseAgressLog: '請先閱讀並同意《服務協議》與《隱私政策》',
  useService: '《服務協議》',
  usePrivacy: '《隱私政策》',
  paper: '試卷',
  pleaseInput: '請輸入',
  reply: '回覆',
  to: '至',
  testTime: '考試時間',
  testDuration: '考試時限',
  passMark: '合格分數',
  residueDegree: '考試次數',
  unlimited: '不限',
  nothing: '無',
  examTips: '溫馨提示：考試一旦開始，倒數結束後將自動交卷。',
  enterTest: '進入考試',
  singleTopicSelection: '單選題',
  multipleChoice: '多選題',
  trueOrFalseQuestions: '判斷題',
  fillsUpTopic: '填充題',
  shortAnswerQuestions: '簡答題',
  onTopic: '上一題',
  nextQuestion: '下一題',
  answerSheet: '答題卡',
  papers: '交卷',
  paperNoSaveTip: '您還有未答試題，確定交卷嗎？',
  paperSaveTip: '確定交卷嗎？',
  didNotAnswer: '未答',
  answered: '已答',
  toLearn: '返回學習任務 >',
  toCourse: '返回課程 >',
  finishExam: '恭喜你，完成考試',
  finishGrade: '您的成績是',
  finishExamTip: '成績只包含客觀題的分數，主觀題需講師評審~以下統計只統計客觀題~',
  finishSuccess: '正確(道)',
  finishError: '錯誤(道)',
  finishTotalTime: '合計時間(分)',
  lookAtAnswer: '查看答案',
  answer: '答案',
  parsing: '解說',
  examinationSite: '考點',
  difficulty: '難度',
  simple: '簡單',
  general: '一般',
  moreDifficult: '較難',
  difficult: '困難',
  pleaseInputEmpty: '請輸入第',
  pleaseInputContent: '個空的答案',
  pleaseInputAnswer: '請輸入答案',
  learnStartEndTime: '已超過可學習時間',
  nextStep: '下一步',
  answerError: '答錯',
  answerSuccess: '答對',
  totalCredit: '累計學分',
  getCertificate: '獲得證書',
  taskCompletionRate: '任務完成率',
  overdueRateTask: '任務逾期率',
  timedTask: '定時任務',
  totalCredits: '累計總學分',
  toadyGet: '今日獲得',
  pleaseInputSearch: '請輸入搜索內容',
  back: '返回',
  paperNeedTo: '該試卷需要在',
  paperFinish: '內完成',
  passTitle: '合格',
  passComplete: '完成',
  goToCommunity: '進入社區交流',
  createName: '制作者',
  examWillBegin: '開始考試',
  continueTest: '繼續考試',
  progress: '進度',
  operation: '操作',
  examinationCompleted: '已完成考試',
  correct: '正確',
  error: '錯誤',
  PersonalIntroduction: '個人介紹',
  noIntroduction: '暫無介紹',
  pleaseEnterContent: '請輸入內容',
  returnHome: '返回首頁',
  noLearn: '請先報名',
  technicalPost: '職位名稱',
  bindPhone: '綁定手機',
  reExamination: '重新考試',
  registrationEnterpriseInformation: '企業信息註冊',
  returnLogin: '返回登錄',
  pleaseInputChangePassword: '請再次輸入密碼',
  passwordNotCheck: '兩次輸入密碼不一致',
  codeError: '驗證碼錯誤',
  accountRegister: '該賬號已存在',
  in: '點擊進入',
  pleaseInputMsg: '請填寫以下信息',
  directory: '目錄',
  courseExam: '課後測試',
  pleaseLearn: '該內容需要再學習',
  cardPlay: '點擊打卡',
  computedPlayCard: '完成打卡才能繼續學習哦~',
  learnSuccess: '完成',
  noUpdate: '未更新',
  free: '免費',
  no: '第',
  chapter: '章',
  chapterNumber: '章節',
  dataLoadError: '數據加載失敗，請稍後重試',
  videoNoUpdate: '該視頻未更新',
  bugWatch: '購買後才可以觀看',
  CreditRecord: '學分記錄',
  CertificateRecords: '證書記錄',
  CourseRecord: '課程記錄',
  LearningProjectRecord: '學習項目記錄',
  startDate: '開始日期',
  endDate: '結束日期',
  export: '導出',
  examRemark: '考前說明',
  CreditsContent: '學分內容',
  creditBehavior: '學分行爲',
  creditOrigin: '學分來源',
  timer: '時間',
  batchDelete: '批量刪除',
  batchRead: '標記已讀',
  lookDetail: '查看詳情',
  pleaseSelectNotice: '請選擇公告!',
  sureDeleteNotice: '確定批量刪除公告?',
  deleteError: '刪除失敗!',
  actionError: '操作失敗!',
  sureReadNotice: '確定批量閱讀公告?',
  courseName: '課程名稱',
  updateTime: '更新時間',
  status: '狀態',
  getCredits: '獲得學分',
  taskName: '任務名稱',
  startTime: '開始時間',
  taskDeadline: '任務期限',
  taskType: '任務類型',
  progressAchievement: '進度/成績',
  beforeDay: '天之前',
  beforeHour: '小時之前',
  beforeMinutes: '分鐘之前',
  sureDeleteComment: '確定刪除這條評論?',
  deleteSuccessComment: '刪除評論成功!',
  beforeLoginComment: '登錄後才可以評論',
  commentSuccess: '評論成功!',
  commentError: '評論失敗!',
  courseType: '課程類型',
  coursewareNumber: '教材數目',
  loginLearn: '登錄學習',
  courseRemove: '課程已下架',
  loginWatch: '登錄觀看',
  addCar: '加入購物車',
  ImmediateSettlement: '立即結算',
  SharingMakes: '分享及賺取',
  inviteFriendsLearn: '邀請好友學習',
  inviteEveryOne: '每邀請一位你將獲得',
  inviteLink: '鏈接邀請',
  copyLink: '複製鏈接',
  AuthorizationPurchase: '授權購買',
  pleaseInputPurchaseNumber: '輸入購買數量',
  loginRate: '登錄後才可以評分',
  rateSuccess: '評分成功',
  loginShare: '登錄後才可以分享及賺取',
  copySuccess: '複製成功',
  loginBuy: '登錄後才可以加入購買',
  loginAddCar: '登錄後才可以加入購物車',
  loginLike: '登錄後才可以點讚',
  likeSuccess: '點讚成功!',
  cancelLike: '已取消點讚!',
  courseRate: '課程評分範圍爲1~5分!',
  learnStudyNum: '學習人數',
  collectionNum: '收藏人數',
  collection: '收藏',
  loginCollection: '登錄後才可以收藏',
  cancelCollection: '成功取消收藏！',
  collectionSuccess: '收藏成功！',
  applyRegistration: '申請報名',
  applySuccess: '已申請報名，須等待審批！',
  applyRegistrationError: '報名審批不通過!',
  applyRegistrationSuccess: '報名已通過審批！',
  registrationApproval: '報名審批中',
  registrationFailed: '審批不通過',
  pleaseLogin: '請先登錄!',
  submitSuccess: '提交成功',
  pleaseDeleteComment: '此操作將永久刪除該評論, 是否繼續',
  BreakDown: '詳細解説',
  pay: '付費',
  nexPage: '下一頁',
  returnFirst: '返回第一頁',
  sureExport: '確認導出數據?',
  fileName: '文件',
  clearLog: '清空記錄',
  oldPassword: '舊密碼',
  pleaseInputOldPassword: '請輸入舊密碼',
  newPassword: '新密碼',
  pleaseInputNewPassword: '請輸入新密碼',
  repeatNewPassword: '重複新密碼',
  pleaseInputRepeatNewPassword: '請輸入確認新密碼',
  pleaseInputSuccessPassword: '請輸入正確的密碼',
  pleaseInputSuccessNewPassword: '請輸入正確的新密碼',
  pleaseInputSuccessRepeatPassword: '請輸入正確的確認密碼',
  pleaseInputSuccessLengthPassword: '密碼長度在 8 到 30 個字符',
  passwordNoSure: '確認密碼與新密碼不一致',
  pleasePwdSizeRule: '請輸入8~30位, 必須包含大小寫字母、特殊字符與數字',
  passwordRule: '密碼必須包含大小寫字母、字符及數字',
  sureClearLog: '確認清空當前記錄?',
  ongoing: '進行中',
  permanently: '永久有效',
  month: '個月',
  pending: '待審批',
  certificationName: '認證名稱',
  completedStudy: '已完成學習',
  pleaseInputCertificate: '請輸入證書名稱',
  automaticIssued: '自動頒發',
  manualIssued: '手動頒發',
  sortIndex: '序號',
  certificateName: '證書名稱',
  userName: '真實名稱',
  certificateNumber: '證書編號',
  certificateOrigin: '證書來源',
  acquisitionTime: '獲取時間',
  issueWay: '頒發方式',
  statusCertificate: '證書狀態',
  outDate: '已過期',
  hold: '持有中',
  loginSuccess: '登錄成功',
  noCompany: '沒有登錄記錄',
  refresh: '重新載入',
  notYetStart: '尚未開始',
  examRecordTitle: '考試記錄',
  canResit: '可補考',
  AnswerTime: '答卷時間',
  paperTotal: '試卷總分',
  answerHour: '作答時限',
  examResult: '考試結果',
  readerUser: '閱卷人',
  detail: '詳情',
  submitTime: '提交時間',
  answerTime: '作答時限',
  evaluation: '評定',
  haveExam: '已考試',
  total: '共',
  pages: '頁，',
  count: '條數據',
  notReadPaper: '暫時無法查看解說',
  screenCuttingNotAllowed: '本次考試不允許切換螢幕，切換螢幕後自動交卷',
  screenCutTipsOne: '警告，切換螢幕超過',
  screenCutTipsTwo: '次後，將自動交卷',
  screenCuttingTimesExceedsLimit: '切換螢幕次數超出限制，自動交卷',
  examTimeOver: '考試時間已經結束',
  remark: '評語',
  applyTip: '無法學習，請等待報名審批',
  applyTip1: '無法學習，請先報名',
  rate: {
    one: '營養不足',
    two: '有點營養',
    three: '養分很多',
    four: '養分充足',
    five: '乾貨滿滿',
  },
  login: {
    DingTalk: '釘釘',
    pleaseLoginDingTalk: '請用“釘釘”掃碼登陸',
    EnterpriseWeChat: '企業微信',
    pleaseLoginEnterpriseWeChat: '請用“企業微信”掃碼登陸',
    toLogin: '賬號登錄',
    toNo: 'ID登錄',
    pleaseInputPhone: '請輸入手機號碼',
    pleaseInputNo: '請輸入ID',
    pleaseInputCode: '請輸入驗證碼',
    sendCode: '獲取驗證碼',
    pleaseInputPassword: '請輸入密碼',
    login: '登錄',
    passwordLogin: '密碼登錄',
    codeLogin: '驗證碼登錄',
    forgetPassword: '重置密碼',
    vaildatePhone: '驗證手機號',
    setNewPsd: '設置新密碼',
    setSuccess: '修改成功',
    register: '註冊',
    pleaseInputSuccessPhone: '請輸入正確手機號碼',
    pleaseSetPassword: '請設置密碼',
    pleaseInputInfo: '請完善以下信息',
    pleaseInputCheckPassword: '再輸入密碼',
  },
  home: {
    admin: '管理頁面',
    sign: '每日簽到',
    task: '我的任務',
    change: '切換企業',
    contribute: '我的貢獻',
    certificates: '我的證書',
    approval: '實操作業審批',
    credit: '學分(分)',
    certificateCount: '證書(張)',
    integrals: '積分(分)',
    changeLang: '切換語言:',
    logout: '退出登錄',
    more: '更多',
    credits: '學分',
    integral: '積分',
    certificate: '證書',
    toLearn: '去學習',
    point: '分',
    part: '張',
    recommended: '推薦',
    hot: '熱門',
    latest: '最新',
    notLearn: '未學習',
    inStudy: '學習中',
    learnComplete: '學習完成',
    learnProgram: '學習項目',
    lineClass: '線上課',
    graphicClass: '圖文課',
    assigned: '指派',
    study: '學習中',
    waitExam: '待考試',
    passExam: '合格',
    notPassExam: '不合格',
    readExam: '待閱卷',
    notExam: '缺考',
    uploadType: '上傳',
    course: '課程',
    learningProjects: '學習項目',
    changeCourse: '換一換',
    learningTask: '學習任務',
    examTask: '考試任務',
    assessmentTask: '測評任務',
    coachTask: '輔導任務',
    top: '置頂',
    rank: '排名',
    name: '姓名',
    logoSize: '大小不能超過5M',
  },
  upload: {
    clear: '取消上傳',
    clearTip: '確認取消上傳嗎?',
    uploadtTip: '正在上傳',
    uploadtTip2: '上傳格式只能是圖片、wrod、pdf格式!',
    resetUpload: '重新上傳',
    download: '下載',
    seek: '查看',
    close: '關閉',
    attachment: '上傳附件',
  },
  VX_account: {
    newestStudyTime: '最終學習時間',
    projectName: '項目名稱',
    trainingRequirement: '培訓要求',
    trainingType: '培訓類型',
    trainingForm: '培訓形式',
    trainingPurpose: '培訓目的',
    planTheNumberofPeople: '計劃人數',
    estimatedCost: '預計費用',
    estimatedClassHours: '預計課時',
    trainingOrgLec: '培訓機構/講師',
    trainingObject: '培訓對象',
    trainingYear: '培訓年份',
    trainingQuarter: '培訓季度',
    trainingMonth: '培訓月份',
    trainingAddress: '培訓地點',
    executiveDepartment: '執行部門',
    superintendent: '負責人',
    executor: '執行人',
    selectMember: '選擇人員',
    determine: '確定',
    createTrainingRequirement: '新建培訓要求',
    trainingRequirementInputPlaceholder: '請輸入培訓要求',
    trainingYearSelectPlaceholder: '請選擇培訓年份',
    trainingQuarterSelectPlaceholder: '請選擇培訓季度',
    trainingMonthSelectPlaceholder: '請選擇培訓月份',
    superintendentSelectPlaceholder: '請選擇負責人',
    planName: '計劃名稱',
    source: '來源',
    creatorName: '制作者',
    gmtCreate: '創建時間',
    relatedTask: '關聯任務',
    trainingTimePeriod: '培訓時間',
    completionRate: '完成率',
    passRate: '合格率',
    moreOperations: '更多操作',
    execute: '執行',
    executionDetails: '執行明細',
    edit: '編輯',
    editMonthlyPlans: '編輯月度計劃',
    expireTime: '到期時間',
    certificateView: '證書查看',
    downloadPicture: '下載圖片',
    certificateNo: '證書編號',
    issueTime: '頒發時間',
    certificateTime: '生效時間',
    certificateeffectiveStartTime: '到期時間',
    obtainType: '取得方式',
    autoIssue: '自動頒發',
    manuIssue: '手動頒發',
    issueSource: '頒發來源',
    issueCause: '頒發原因',
    quashCause: '撤銷原因',
    todayCount: '今日獲得學分',
    totalCount: '累計總學分',
    type: '類型',
    changeTime: '變更時間',
    backToLearn: '返回學習',
    backToExamTask: '返回考試任務',
    inEffect: '生效中',
    rescinded: '已撤銷',
    indefiniteTime: '無限期',
    productName: '商品名稱',
    exchangeCount: '兌換數量',
    consumptionIntegral: '消耗積分',
    exchangeTime: '兌換時間',
    issueProtectTime: '發放時間',
    todayIntegralTotal: '今日累計積分',
    integralTotal: '累計積分',
    integralRules: '積分規則',
    integralUsable: '可用積分',
    toExchangeProduct: '兌換積分',
    courseInteraction: '課程互動',
    contribution: '貢獻',
    integralBehavior: '積分行爲',
    operationName: '操作名稱',
    singleScore: '單次獎懲分值',
    upperIntegralDay: '每日積分上限',
    noUpperIntergral: '無限',
    validityPeriod: '有效期限',
    integralRulesTips: '溫馨提示',
    integralRulesTipOne: '1、累計積分=累計獲得的積分-因抵值內容等被扣除的積分',
    integralRulesTipTwo: '2、學員可使用積分在相應頁面兌換商品，兌換要求、使用規則等詳情具體以頁面展示爲準。',
    integralRulesTipThree: '3、由於受限於庫存數量，積分商品兌完即止，先兌先得。',
    notFinish: '未結束',
    finished: '已結束',
    settled: '已結算',
    taskNamePlaceholder: '根據任務名稱搜索',
    offlineClassName: '線下課名稱',
    classStartTime: '開課時間',
    courseAddress: '上課地址',
    trainingAttachment: '培訓附件',
    courseEvaluate: '評價課程',
    evaluateCourse: '給課程評價',
    questinonaireModelCancel: '取 消',
    questinonaireModelDetermine: '確 定',
    lecturerEvaluate: '評價講師',
    evaluateLecturer: '給講師評價',
    totalEvaluateCount: '應評價人數',
    waitEvaluateCount: '待評價人數',
    completeEvaluateCount: '已評價人數',
    evaluateTrainees: '給學員評分',
    evaluateTrainee: '給學員評價',
    questionaireEvaluate: '問卷評價',
    remove: '移除',
    announcement: '公告',
    announcementTitle: '公告標題',
    userMyEvaluate: '我的評價',
    userEvaluateMe: '為我評分',
    traineesEvaluate: '學員對我的評價',
    lecturerEvaluateMe: '講師對我的評價',
    commitPracticeTask: '提交實操作業',
    taskRequirement: '作業要求',
    attachment: '附件',
    step: '第{index}步',
    pracDialogPlaceholder: '請描述操作步驟（選填）',
    pracDialogTips: '可以附加圖片、文檔、壓縮包，最多可附加5個附件',
    addStep: '添加步驟',
    saveDraft: '儲存草稿',
    commit: '提交',
    submitWarning: '請填寫內容',
    startSurvey: '開始調查',
    statisticsResult: '統計結果',
    selfEvaluate: '自評',
    othersEvaluate: '他評',
    awardCredit: '獎勵學分',
    repeatedExam: '重考',
    examStatics: '考試數據',
    waitEvaluation: '待評定',
    allMessage: '全部消息',
    unreadMessage: '未讀消息',
    readMessage: '已讀消息',
    userMsgPlaceholder: '請選擇消息類型',
    messageName: '消息名稱',
    messageType: '消息類型',
    messageDetails: '消息詳情',
    close: '關閉',
    batchReadTip: '確定將勾選的{length}條消息標爲已讀？',
    batchDeleteTip: '確定刪除已勾選的{length}條消息？',
    deleteTip: '刪除提示',
    attendanceManagement: '出席管理',
    attendanceName: '出席名稱',
    relatedOfflineClass: '關聯線下課',
    sourceName: '來源名稱',
    signIn: '簽到',
    signOut: '簽退',
    attendanceQRCode: '出席二維碼',
    scoreRecord: '積分記錄',
    exchangeRecord: '兌換記錄',
    effectiveStatusPlaceholder: '請選擇證書狀態',
    startDatePlaceholder: '開始日期',
    endDatePlaceholder: '結束日期',
    issueStatusPlaceholder: '請選擇發放狀態',
    notIssue: '待發放',
    issued: '已發放',
    surveyTask: '調查任務',
    evaluationTask: '評估任務',
    trainingPlan: '培訓計劃',
    evaluationTaskOfNamePlaceholder: '根據評估任務名稱搜索',
    yearPlaceholder: '請選擇年份',
    quarterPlaceholder: '請選擇季度',
    monthPlaceholder: '請選擇月份',
    planOfNamePlaceholder: '根據計劃名稱搜索',
    uploadTips: '點擊上傳',
    executorSelect: '選擇執行人',
    endExamTip: '是否結束考試?',
    handInExamPaper: '是否交卷？',
    autoHandInExamPaper: '本次考試不允許切換螢幕，正在自動交卷',
    trainRecords: '培訓記錄',
    basicInfo: '基本信息',
    taskTitlePlaceholder: '請輸入任務名稱',
    classification: '所屬分類',
    validityTime: '有效期',
    startTimePlaceholder: '開始時間',
    endTimePlaceholder: '結束時間',
    departmentName: '部門',
    costBudget: '費用預算',
    yuan: '元',
    stageTask: '階段任務',
    studyMode: '學習模式',
    notSupportOfflineTips: '解鎖模式暫不支持線下課',
    freeMode: '自由模式',
    unlockMode: '解鎖模式',
    stageContentSetting: '階段內容設置',
    stage: '第{index}階段',
    stageName: '階段名稱',
    pleaseInputStageName: '請輸入階段名稱',
    stageExplanation: '階段說明',
    unlockTimePlaceholder: '解鎖時間',
    stageContent: '階段內容',
    name: '名稱',
    contentDuration: '內容時長',
    nothaveDuration: '暫無時長',
    pleaseSelectUnlockTime: '請選擇解鎖時間',
    changeResource: '更換資源',
    delete: '刪除',
    drag: '拉',
    withinStageThroughLevel: '分階段解鎖',
    betweenStageThroughLevel: '階段之間解鎖',
    unlockTaskTipOne: '啟用後，用戶必須在此階段中按順序學習內容',
    unlockTaskTipTwo: '啟用後，用戶必須在各階段中按順序學習內容（例如：用戶學完階段一後才能學習階段二內容）',
    progressSync: '進度同步',
    progressSyncTip: '添加的課程/素材同步員工在其他途徑的學習進度。<br/> 同步機制：播放時才同步進度',
    synchronize: '同步',
    notSynchronize: '不同步',
    estimatedTaskHours: '估算學時',
    estimatedTaskHoursStandards: '估算學時標準：<br/> 視頻：按照視頻時長統計<br/> 音頻：按照音頻時長統計<br/> 其他內容不計入估算<br/> 學習項目統計包含課件、課程中的音視<br/> 頻時長以及線下培訓中的開課時間時長',
    assignRange: '指派範圍',
    assignRangeSetting: '設置指派範圍',
    select: '選擇',
    selectedObject: '已選對象',
    selectedPerson: '已選人員',
    qualifiedSetting: '合格設置',
    qualifiedStandardTip: '學員學習整個項目是否合格的依據',
    taskProgressReach: '學習進度達到',
    examPass: '考試通過',
    practicePass: '實操作業通過',
    qualifiedRewardTip: '學員是否可以獲得項目合格獎勵的依據',
    getCertificate: '合格後獲得證書',
    otherSetting: '其他設置',
    learningTimeSetting: '設置文檔學習時長',
    learningTimeTip: '強制學習課題時長',
    documentLearningTime: '文檔學習時長',
    secondsPerPage: '秒/頁',
    learningTimeSettingTips: '1.如未設置，員工學習文檔進度用瀏覽頁數來計算，不計入學習時長。<br/> 2.設置後，員工學習進度用學習時長來計算，學習時長最大計爲每頁學習時長*文檔頁數。',
    faceRecognition: '人臉識別',
    faceRecognitionTip: '啟用後，用戶必須先透過面部識別，然後才能開始學習每個階段',
    save: '保存',
    publish: '發佈',
    pleaseSelectAssignRange: '請選擇指派範圍',
    pleaseSelectEligibilityCriteria: '請選擇合格標準',
    pleaseSelectClassification: '請選擇所屬分類',
    pleaseInputValidityPeriod: '請輸入有效期',
    pleaseInputTaskName: '請輸入任務名稱',
    pleaseSelectStageContent: '請選擇階段內容',
    selectAssignRange: '選擇指派範圍',
    firstStage: '第一階段',
    delTip: '確定刪除【{name}】？刪除後對應的配置內容也會一起刪除，請謹慎操作',
    changeFile: '更換文件',
    selectFile: '選擇文件',
    changeOnlineCourse: '更換線上課',
    changeGraphicCourse: '更換圖文課',
    changeLiveCourse: '更換直播課',
    selectOnlineCourse: '選擇線上課',
    selectGraphicCourse: '選擇圖文課',
    selectLiveCourse: '選擇直播課',
    addExamination: '添加考試',
    editExamination: '編輯考試',
    addHomework: '添加實操作業',
    editHomework: '編輯實操作業',
    addOfflieCourse: '添加線下課',
    editOfflieCourse: '編輯線下課',
    addAttendance: '添加考勤',
    editAttendance: '編輯考勤',
    addInvestigate: '添加調查',
    editInvestigate: '編輯調查',
    deletePhraseTip: '刪除後，所有已派發任務會同步刪除，進度重新計算。該操作不可撤消，確定要刪除？',
    selectMembers: '選擇指派範圍',
    selectCertificate: '證書選擇',
    stageTaskTip: '階段內容不能爲空',
    stageContentTip: '階段中的內容不能爲空',
    assignCount: '指派人數',
    participationCount: '參加人數',
    completeCount: '完成人數',
    completeRate: '完成率(%)',
    passRatePercent: '合格率(%)',
    unbind: '解除綁定',
    publishSuccess: '發佈成功',
    unbindTip: '解除綁定後，任務和計劃不再產生關聯關係。確定要解除綁定？',
    deleteDetailTip: '確定刪除？',
    modifierName: '提交人',
    planDetails: '計劃明細',
    expectTrainingMonth: '預計培訓月份',
    assignor: '指派人',
    beyondPeriod: '任務已超出有效期，不可進行學習',
    notLive: '暫未開播',
    liveHasEnd: '直播已結束，視頻生成中~',
    videoGenerating: '視頻生成中~',
    totalPoint: '總',
    essayPoint: '論文得分',
    month: '月',
    integralTypePlaceholder: '請選擇積分類型',
    sheet: '{count}張',
    reLearn: '重新學習',
    announcementTotal: '全部公告',
    announcementUnread: '未讀公告',
    announcementRead: '已讀公告',
    examExpired: '考試已超過有效期！',
    inputAnswerPlaceholder: '請輸入第{index}個空的答案',
    finishInTime: '該試卷需要在{count}內完成',
    treeName: '存儲位置',
    fileNameSearchPlaceholder: '根據文件名稱搜索',
    currentPageData: '所有頁面',
    selectCountAtMost: '最多選擇{count}張！',
    selectContentPlaceholder: '請選擇數據',
    resourceClassification: '資源分類',
    courseNameSearchPlaceholder: '根據課程名稱搜索',
    originalOrNotPlaceholder: '內容類型',
    original: '原創',
    unoriginal: '非原創',
    selectCourse: '選擇課程',
    pleaseSelectCourse: '請選擇課程',
    quotePaper: '引用試卷',
    examName: '考試名稱',
    passLine: '合格線',
    passScore: '合格分數',
    examTotalScore: '試題總分',
    examDurationTip: '時長不超過300分鐘',
    examDescription: '考試說明',
    examSetting: '考試設置',
    facialRecognitionTip: '啟用後，在移動設備上學習時，您需要先通過人臉識別才能開始學習',
    unlimited: '不限次數',
    suspendAfterPassing: '通過後完成',
    customTimes: '自定義次數',
    gradeSetting: '成績設置',
    recordLatestContent: '記錄最新數據',
    recordHighestContent: '記錄最高成績',
    switchScreenCount: '切換螢幕次數',
    switchScreenTip: '設置防切換螢幕後，PC端填空題和簡答題學員作答時無法上傳圖片',
    notAllowed: '不允許',
    custom: '自定義',
    answerOrder: '作答順序',
    answerOrderTipOne: '試題隨機排序：隨機打亂各問題的排序',
    answerOrderTipTwo: '選項隨機排序：隨機打亂各題目內的選項排序',
    questionOutOfOrder: '試題隨機',
    optionOutOfOrder: '選項隨機',
    viewSetting: '用戶顯示設置',
    display: '顯示',
    hide: '隱藏',
    viewAnswerDetails: '查看答案',
    afterMarking: '閱卷後',
    afterPassing: '合格後',
    examEnd: '考試結束',
    invisible: '不可查看',
    viewContent: '查看內容',
    viewContentTypeOne: '對錯和答案解說',
    viewContentTypetTwo: '僅顯示對錯',
    knowledgePoint: '知識點',
    viewRank: '查看排行',
    afterHandingInPaper: '交卷後',
    markingSetting: '計分設置',
    markingType: '計分方式',
    markingTypeOne: '自動評分',
    markingTypeTwo: '手動評分',
    saveAndAdd: '保存並繼續添加',
    selectPaperPlaceholder: '請選擇試卷',
    selectPaper: '選擇試卷',
    selectMarkingUserPlaceholder: '選擇閱卷人',
    addSuccess: '添加成功',
    homeworkName: '作業名稱',
    homeworkNamePlaceholder: '請簡單說明',
    taskRequirementPlaceholder: '請輸入實操規範要求',
    attachmentTip: '上傳的附件將會對學員展示作爲作業參考',
    fileName: '文件名',
    homeworkType: '作業類型',
    homeworkTypeOne: '常規作業',
    homeworkTypeTwo: '新人作業',
    homeworkTypeSelect: '類型不對學員展示，僅在“實操報表”中篩選使用',
    selectAuditUser: '選擇審批人',
    courseDuration: '課程時長',
    confirmCourse: '講師確認課程安排',
    notComputeCourseCost: '不計課時費',
    contentDescriptionPlaceholder: '可寫下詳細的課程簡介或培訓目的',
    moreSetting: '更多設置',
    lecturerEvaluateTrainees: '講師評價學員',
    start: '開啓',
    directEvaluation: '直接評分',
    questionnaire: '問卷調查',
    selectQuestionnaire: '選擇問卷',
    fullMarks: '滿分',
    traineesEvaluateLecturer: '學員評價講師',
    evaluationUseStar: '星評',
    traineesEvaluateCourse: '學員評價課程',
    offlineExamination: '線下評估',
    offlineExaminationTipOne: '啟用後允許錄入學員成績',
    offlineExaminationTipTwo: '講師、簽到負責人和管理員可以錄入學員成績',
    messageRemind: '消息提醒',
    beforeTrainingStart: '培訓開始前',
    remindLecturer: '提醒講師',
    remindStudent: '提醒學員',
    remindTip: '分鐘發送提醒',
    other: '其他',
    allowUpload: '允許上傳活動照片等附件',
    pleaseSelectLecturer: '請選擇講師',
    selectLecturer: '選擇講師',
    relatedOfflineClassTip: '關聯線下課後,該考勤僅對被關聯的線下課有效',
    offlineSelectPlaceholder: '請選擇線下課',
    attendanceTimeLimit: '考勤時間限制',
    detailedAddress: '詳細地址',
    attendanceEffectiveRange: '考勤有效範圍',
    meter: '米',
    addAttendanceAddress: '添加考勤地址',
    dynamicQRCode: '動態二維碼',
    dynamicQRCodeTip: '開啓後二維碼重新載入頻率：10秒',
    remindAttendanceManager: '提醒考勤負責人',
    beforeAttendance: '考勤開始前',
    reminderMinute: '分鐘提醒',
    attendanceTimeLimitTip: '請選擇考勤時間限制',
    signInTimeTip: '請選擇簽到時間',
    signOutTimeTip: '請選擇簽退時間',
    selectUser: '選擇負責人',
    pleaseSelectQuestionnaire: '請選擇問卷',
    investigationName: '調研名稱',
    searchContent: '搜索內容',
    user: '人員',
    noAuthTipOne: '您沒有該部門管轄權限，請選擇該',
    noAuthTipTwo: '部門下有管轄權限的子部門人員',
    empNo: 'ID',
    noContent: '沒有搜到內容',
    selectedUser: '已選員工',
    pleaseSelectUser: '請選擇添加對象/員工',
    paperNameSearchPlaceholder: '根據試卷名稱搜索',
    create: '創建',
    addressInputPlaceholder: '輸入地址搜索',
    pleaseSelectAddress: '請選擇地址',
    classificationCatalogue: '分類目錄',
    questionnaireName: '問卷名稱',
    certificateSearchPlaceholder: '根據證書名稱搜索',
    department: '部門',
    position: '崗位',
    selectedUsers: '已選對象/員工',
    lecturerOrTagSearchPlaceholder: '根據講師或標籤搜索',
    lectured: '已授課',
    yes: '是',
    no: '否',
    goToDetailTip: '請移步到移動端學習',
    known: '知道了',
    submitTime: '交卷時間',
    passScores: '及格分數',
    importantCourse: '重點課',
    attendanceNamePlaceholder: '根據考勤名稱搜索',
    startOnLive: '開播時間',
    signInQrCode: '簽到二維碼',
    signOutQrCode: '簽退二維碼',
    refreshQrCode: '重新載入二維碼',
    relatedCourse: '關聯課程',
    attendanceAddress: '考勤地址',
    attendanceType: '考勤方式：掃碼（考勤地址{range}米內）',
    svgList: {
      materialSvg: '素材',
      onlineSvg: '線上課',
      graphicSvg: '圖文課',
      liveSvg: '直播課',
      examSvg: '考試',
      homeworkSvg: '實操作業',
      offlineSvg: '線下課',
      checkinSvg: '考勤',
      investigateSvg: '調查',
    },
    trainingTypeEnum: {
      online: '線上',
      offline: '線下',
      mixed: '混合',
      other: '其他培訓',
    },
    trainingMonthEnum: {
      January: '1月',
      February: '2月',
      March: '3月',
      April: '4月',
      May: '5月',
      June: '6月',
      July: '7月',
      August: '8月',
      September: '9月',
      October: '10月',
      November: '11月',
      December: '12月',
    },
    postStatusEnum: {
      unpublished: '未發佈',
      published: '已發佈',
      reject: '駁回',
      inApproval: '審批中',
    },
    ruleTypeEnum: {
      courseInteraction: '課程互動',
      contribution: '貢獻',
      attendance: '出席',
    },
    operateSceneEnum: {
      operateSceneOne: '完成一次課程評分(線上課、學習項目)',
      operateSceneTwo: '完成一次課程點讚(線上課、學習項目)',
      operateSceneThree: '完成一次課程評論/回覆(線上課、學習項目)',
      operateSceneFour: '完成一次線上授課(學習項目)',
      operateSceneFive: '完成一次線上授課(線上課)',
      operateSceneSix: '完成一次線下授課(線下課)',
      operateSceneSeven: '完成一次線上授課(直播課)',
      operateSceneEight: '遲到/早退≤30分鐘',
      operateSceneNine: '遲到/早退>30分鐘，曠課',
      operateSceneTen: '考勤缺卡(線下課)',
    },
    validityPeriodTypeEnum: {
      disposable: '一次性',
      everyDay: '每日',
    },
    studyStatusEnums: {
      notLearned: '未學習',
      learning: '進行中',
      ended: '結束',
    },
    trainingQuarterEnum: {
      firstQuarter: '第一季度',
      secondQuarter: '第二季度',
      thirdQuarter: '第三季度',
      fourthQuarter: '第四季度',
    },
    courseTypeEnum: {
      video: '線上課',
      imageText: '圖文課',
      live: '直播課',
      offline: '線下課',
    },
    changeResourceTypeEnums: {
      vodOnline: '線上課',
      imageText: '圖文課',
      studyProject: '學習項目',
      studyTask: '學習任務',
      examTask: '考試任務',
      postMap: '崗位地圖',
      evaluationTask: '測評任務',
      postMapPath: '崗位地圖路徑',
    },
    changeCategoryEnum: {
      courseInteractive: '課程互動',
      contribution: '貢獻',
      attendance: '出席',
      lowValueInteraction: '低值互動',
      lowValueContent: '低值內容',
      integralExchange: '積分兌換',
      integralClearing: '積分清零',
    },
    taskTypeEnums: {
      studyProject: '學習項目',
      studyTask: '學習任務',
      examTask: '考試任務',
      timeExam: '定時考試',
      external: '外部',
      manualIssue: '手動頒發',
      postCertification: '崗位認證',
      evaluationTask: '測評任務',
    },
    NoticeTypeEnum: {
      studyRemind: '學習提醒',
      monthlyPlan: '月度計劃',
    },
    uploadType: {
      all: '全部',
      video: '視頻',
      audio: '音頻',
      document: '文檔',
      picture: '圖片',
      compressedPackage: '壓縮包',
      scorm: 'SCORM教材包',
      h5: 'H5教材包',
    },
    lecturerType: {
      internal: '內部講師',
      external: '外部講師',
    },
    gainCertOrNot: {
      gained: '已獲得',
      notGain: '未獲得',
    },
    TrainingStatusEnum: {
      notStart: '未開始',
      ongoing: '進行中',
      ended: '結束',
    },
    examStatusEnum: {
      waitTest: '待考試',
      pass: '及格',
      fail: '不及格',
      waitMark: '待閱卷',
      absent: '缺考',
    },
    monthKeyList: {
      jan: '1月',
      feb: '2月',
      mar: '3月',
      apr: '4月',
      may: '5月',
      jun: '6月',
      jul: '7月',
      aug: '8月',
      sep: '9月',
      oct: '10月',
      nov: '11月',
      dec: '12月',
    },
  },
  VX_certificate: {
    certificateSearch: '證書查詢',
    search: '查詢',
    certificateDetails: '證書詳情',
    certificateNoPlaceHolder: '請輸入證書號碼',
    pleaseInputcertificateNo: '請輸入證書編號！',
  },
  VX_development: {
    finishEvaluate: '完成評估',
    abilityLevel: '能力等級',
    levelDescription: '等級描述',
    highPerformanceBehaviorDesc: '高績效行爲描述',
    evaluateUser: '評估對象',
    abilities: '能力項',
    previousAbility: '上一個能力項',
    nextAbility: '下一個能力項',
    evaluateTip: '尚未完成評估，確定退出',
    evaluate: '評估',
    evaluationReport: '評估報告',
    evaluatedUser: '被評估人',
    reportTime: '報告時間',
    abilityName: '能力項名稱',
    positionRequirement: '崗位需求',
    superiorEvaluation: '上級',
    subordinateEvaluation: '下級',
    sameLevelEvaluation: '平級',
    finalResult: '最終結果',
    completeEvaluationTask: '完成評估任務',
    completeProgramme: '完成提示方案',
    othersEvaluateTip: '他評評估進度變化規則: 相應被評估人關聯的所有需要評估的人評估完成後，纔會彙總完成評估部分的進度變化。<br> 舉例：他評維度有兩人，分別是A和B。A評估完了，界面上的他評的進度不會變化，需要等B評估完成纔會彙總進度變化。',
    evaluatedUserSearchPlaceholder: '根據被評估人搜索',
    taskStatusPlaceholder: '請選擇任務狀態',
    batchEvaluate: '批量評估',
    evaluationRelation: '評估關係',
    evaluationStatusUpdateTime: '狀態更新時間',
    taskStatus: '任務狀態',
    evaluationStatus: '評估狀態',
    evaluated: '已評估',
    notEvaluate: '待評估',
    waitingOthersEvaluate: '等待他評',
    startEvaluate: '開始評測',
    evaluationTestReport: '評測報告',
    advanceProgramme: '提升方案',
    commonAbility: '通用能力',
    positionAbility: '崗位能力',
    positionDemand: '崗位要求',
    previous: '上一個',
    next: '下一個',
    abilityExplanation: '能力項釋義',
    historyEvaluationTip: '任務結果以最新的評估任務能力項結果爲準',
    evaluationDimension: '評估維度',
    abilityCount: '能力項數',
    evaluationTime: '評估時間',
    historyEvaluation: '歷史評估',
    criticalTask: '關鍵任務',
    totalCount: '總數',
    description: '描述',
    qualificationTipOne: '達成關鍵任務是完成崗位晉升的關鍵一步',
    requireCertificate: '所需證書',
    qualificationTipTwo: '獲得所需證書是完成崗位晉升的關鍵一步',
    organizationContribution: '組織貢獻',
    qualificationTipThree: '獲得組織貢獻是完成崗位晉升的關鍵一步',
    positionInfomation: '崗位信息',
    positionVideoIntroduction: '崗位視頻介紹',
    postChannelRankName: '職級',
    positionResponsibility: '崗位職責',
    inPositionRequirement: '任職要求',
    studyProgramme: '學習方案',
    qualificationProgress: '合格進度',
    completeProgress: '進度完成{percent}%',
    taskItem: '任務項',
    seekAllCriticalTask: '查看全部關鍵任務',
    seekAllRequireCertificate: '查看全部所需證書',
    seekAllOrganizationContribution: '查看全部組織貢獻',
    improvementSuggestion: '提升建議',
    ability: '能力項',
    importResult: '導入結果',
    taskResult: '任務結果',
    developmentTip: '當前存在兼崗情況，可在此切換崗位查看不同',
    developmentDescriptionTip: '說明：此處展示當前崗位已加入的可晉升通道，如可晉升崗位與當前崗位不屬於同一通道下，則展示在跨通道晉升內',
    seek: '前往查看',
    developmentEmptyTip: '您當前沒有崗位，請聯繫管理員加入崗位',
    positionNotPublished: '該崗位未發佈',
    positionNoStudyProgramme: '該崗位未配置學習方案',
    evaluationAbility: '評估能力',
    relateTypeEnum: {
      subEvaluate: '下級評估',
      levelEvaluate: '平級評估',
      superEvaluate: '上級評估',
    },
    postType: {
      prePosition: '前序崗位',
      curPosition: '當前崗位',
      proPosition: '晉升崗位',
    },
  },
  VX_home: {
    taskNameShow: '此處顯示任務名稱',
    designatorNameShow: '此處顯示用戶暱稱',
    courseNameShow: '此處顯示課程名稱',
    noLecturer: '暫無講師',
    rankList: '排行榜',
    creditsRank: '學分排行',
    integralRank: '積分排行',
    certificateRank: '證書排行',
    rank: '排行',
    totalCredits: '總學分',
    totalCertificates: '總證書',
    examNameShow: '此處顯示考試名稱',
    userName: '暱稱',
    department: '所屬部門',
    examScore: '考試成績',
    examRankList: '考試公示榜',
    noAuthTip: '暫無權限',
    noAuthTipDetail: '根據管理者配置規則，您暫無權限查看當前考試排行榜',
    ranking: '排名',
    examScores: '考試得分',
    examViewTip: '請選擇需要查詢的考試，排行將按考試具體配置為準',
    score: '成績',
    pageCountTip: '共{count}頁',
    dataCountTip: '{count}條數據',
    studyNameShow: '此處顯示任務名稱',
    learnTaskNameShow: '此處顯示學習任務名稱',
    name: '員工暱稱',
    investigateTip: '溫馨提示：本問卷爲匿名問卷，您可以放心的如實填答問卷，感謝配合！',
    gradeDescriptionPlaceholder: '請輸入評分說明',
    mustInput: '（必填）',
    phoneNumPlaceholder: '請輸入手機號',
    phoneNumInputTip: '請輸入正確的手機號',
    enableSmsVerifyPlaceholder: '請輸入驗證碼',
    commitSuccessTip: '提交成功！感謝您完成此次問卷調查',
    viewResult: '查看統計結果 >',
    viewOther: '查看其他',
    phoneNumberFalseTip: '第{key}道題目的手機號不正確，請重新輸入',
    answerInputTip: '請選擇第{index}道題目的答案，再提交問卷。',
    otherInputTip: '請輸入第{index}道題目的其他選項的詳細內容，再提交問卷。',
    inputTip: '請輸入第{index}道題目的答案，再提交問卷。',
    minLengthInputTip: '第{index}道題目的答案最少{length}字',
    phoneNumberTip: '請輸入第{index}道題目的手機號，再提交問卷。',
    enableSmsVerifyTip: '請輸入第{index}道題目的短信驗證碼，再提交問卷。',
    gradeTip: '請選擇第{index}道題目的評分，再提交問卷。',
    gradeDescriptionTip: '請填寫第{index}道題目的評分說明，再提交問卷。',
    writeCount: '截止目前已有{count}名用戶參與本次問卷調查，如下爲其數據統計結果：',
    statisticsResultTip: '僅支持統計單選題、多選題、評分',
    questionOption: '題目選項',
    totalCount: '合計數',
    percent: '佔比',
    gradeOption: '評分選項',
    optionCharacter: '選項文字',
    scores: '分值',
    writeCounts: '本題填寫人數',
    noStatistics: '暫未統計內容',
    noLecturerName: '暫無講師名稱',
    lecturerField: '此處顯示講師擅長領域',
    integralMall: '積分商城',
    toIntegralMall: '前往積分商城兌換商品',
    toExchange: '去兌換',
  },
  VX_knowledge: {
    playErr: '播放出錯提示',
    playErrTip: '播放異常，用戶可在 { count }秒後繼續播放! 當次學習記錄作廢！',
    noNextChapterTip: '沒有下一章',
    noNextSectionTip: '沒有下一節',
    autoPlay: '自動播放下一章節！',
    videoNotUpdate: '該視頻未更新',
    videoWatchTip: '請購買後觀看',
    enrollTip: '請先登錄後進行申請',
    lecturer: '授課講師',
    attendenceArrangement: '考勤安排',
    userNamePlaceholder: '根據姓名搜索',
    deptPlaceholder: '請選擇部門',
    pictureUserName: '頭像/姓名',
    empNo: 'ID',
    post: '所屬崗位',
    email: '聯繫郵箱',
    joinTime: '加入時間',
    trainingProgress: '培訓總進度',
    signInTime: '簽到時間',
    signOutTime: '簽退時間',
    traningDuration: '線下培訓時長',
    assessScore: '考覈成績',
    assessComment: '考覈評語',
    assessAttachmentCount: '考覈評分附件',
    assessUserName: '錄入成績人員',
    assessAttachment: '對附件進行評分',
    noResource: '暫無可學習的資源~',
    chapterCount: '第{count}章',
    relatedLecturer: '關聯講師',
    myProgress: '我的進度',
    progressFinished: '進度完成{percent}%',
    examPassCount: '考試及格{count}場',
    practicePassCount: '實操作業通過{count}場',
    applyNotPass: '報名已被拒絕，查看原因',
    reApply: '重新報名',
    learned: '{count}人已學習',
    rejectReason: '拒絕原因',
    courseLoadingFailTip: '課程信息加載失敗',
    dataLoadingFailTip: '數據加載失敗，請稍後重試',
    gradeGroupTitle: '星評（{count}）',
    comprehensiveGrade: '綜合評分',
    gradeTime: '評分時間',
    auditScore: '審批得分',
    waitingGrade: '等待講師評分',
    morning: '上午',
    afternoon: '下午',
    attendanceManager: '考勤負責人',
    notSignIn: '未簽到',
    notSignOut: '未簽退',
    courseExpired: '課程已過期',
    myGrade: '我的評分',
    daysAgo: '{day}天之前',
    hoursAgo: '{hour}小時之前',
    minutesAgo: '{minute}分鐘之前',
    taskExpired: '任務已過期',
    evaluatedLecturer: '已評價講師',
    evaluateLecturer: '評價講師',
    evaluatedCourse: '已評價課程',
    evaluateCourse: '評價課程',
    from: '來自',
    taskContent: '作業內容',
    count: '{count}個',
    evaluationTip: '感謝您的評價!',
    signStatus: {
      normal: '正常',
      late: '遲到',
      leaveEarlier: '早退',
      absent: '缺勤',
      askingForLeave: '請假',
    },
    LiveStatusEnum: {
      notLive: '待直播',
      enterLive: '進入直播',
      ended: '已結束',
      replay: '回放觀看',
    },
  },
  VX_utils: {
    yearsAgo: '{year}年前',
    monthsAgo: '{month}個月前',
    weeksAgo: '{week}周前',
    daysAgo: '{day}天前',
    hoursAgo: '{hour}小時前',
    minutesAgo: '{minute}分鐘前',
    justNow: '剛剛',
    oneDayAgo: '1天前',
    month: '月',
    date: '日',
    hours: '時',
    minutes: '分',
    hour: '小時',
    day: '天',
    second: '秒',
    minute: '分鐘',
  },
  VX_company: {
    companySelect: '選擇企業',
    myCompany: '我的企業',
    companyCreateTip: '最多創建10個企業',
    registerCompany: '註冊企業',
    enterCompany: '進入企業',
    basicInfoInput: '填寫基本信息',
    companyCreationSuccessTip: '恭喜你！企業創建成功,獲得7天免費試用',
    backToHome: '回到首頁',
  },
  VX_header: {
    center: '機構中心',
    keywordInputPlaceholder: '請搜索關鍵詞',
    targetNum: {
      1: '首頁',
      2: '知識庫',
      3: '講師中心',
      4: '積分商城',
      5: '人才發展',
      6: '個人中心',
    },
    languageTypeEnum: {
      zh: '中文',
      zhhk: '中文繁體',
      en: 'English',
      ja: '日本語',
    },
  },
  VX_login: {
    agreement: '協議',
    refuse: '拒絕',
    agree: '同意',
    contactManagerTip: '請聯繫管理員',
    pleaseInputLoginPassword: '請設置8-30位數字、字母符號組合',
    sendCodeSuccess: '驗證碼發送成功',
    reGet: '重新獲取',
    noAuth: '暫無權限查看',
  },
  VX_integral: {
    exchangeCondition: '兌換條件',
    conditionTip: '需學完以下全部內容方可兌換',
    toLearn: '前往學習',
    studyWarningTipOne: '此內容已被取消發佈暫不可學習，請聯繫管理員',
    studyWarningTipTwo: '您無權限查看此內容，如需查看請聯繫管理員',
    consumeIntegral: '需消耗:<span><b>{count}</b>積分</span>',
    alreadyExchangeCount: '已兌{count}',
    deadline: '截止時間',
    finishSpecificContent: '學完指定內容',
    afterExchangingNotice: '兌後須知',
    exchangeTip: '兌換後不支持在線退換',
    surplusStock: '剩餘庫存',
    piece: '件',
    exchangeLimit: '（限兌{count}件）',
    immediateExchange: '立即兌換',
    noStock: '已兌光',
    confirmOrder: '確認訂單',
    stockCount: '庫存{count}件',
    consumption: '需消耗',
    exchangeSuccess: '兌換成功!',
    goodsDescription: '商品描述',
    rankType: '排序方式',
    integralRange: '積分區間',
    stockStatus: '庫存狀態',
    inStock: '有庫存',
    integralConversionConditionTypeEnum: {
      course: '課程',
      studyProject: '學習項目',
      studyTask: '學習任務',
    },
  },
  VX_lecturer: {
    inputScore: '登記考試成績({score})',
    notInput: '待登記',
    hasInput: '已登記',
    getScore: '得分：',
    pass: '及格',
    assessComment: '評語：',
    uploadAttachment: '上傳附件：',
    assessTip: '上傳的附件會對學員展示。支持文檔、壓縮包，最多支持5個附件',
    inputScorePlaceholder: '請輸入得分',
    uploadData: '上傳資料',
    attachmentTip: '支持圖片、文檔、最多支持10個附件, 上傳大小不能超過 2G',
    uploadAttachmentPlaceholder: '請選擇附件上傳',
    evaluateUserCount: '評價學員({count})',
    notEvaluate: '待評價',
    evaluated: '已評價',
    noLabel: '暫無標籤',
    courseTaughtCount: '{count}個已授課程',
    questionnaireCount: '問卷({count})',
    courseCount: '課程數量',
    studyProjectCount: '學習項目數',
    lecturerIntroduction: '講師介紹',
    offlineCourse: '線下課',
    notStartCount: '未開始({count})',
    liveCount: '進行中({count})',
    endedCount: '已結束({count})',
    address: '地址',
    purpose: '目的',
    assessScore: '考覈評分',
    evaluateUser: '評價學員',
    searchByName: '根據{name}名稱搜索',
    lecturerLevel: '講師等級',
    searchByLecturerPlaceholder: '根據講師名稱搜索',
    courseTypeEnum: {
      onlineCourse: '線上課',
      pictureCourse: '圖文課',
      liveCourse: '直播課',
      studyProject: '學習項目',
      offlineCourse: '線下課',
    }
  },
  VX_common: {
    callCustomerService: '聯繫客服',
    customerServiceQQ: '客服QQ',
    link: '友情鏈接',
    websiteNav: '網站導航',
  },
  VX_register: {
    commitApplication: '提交申請',
    pleaseInputUserName: '請輸入用戶姓名',
    pleaseInputPhone: '請輸入手機號碼',
    pleaseInputCompanyName: '請輸入公司名稱',
    pleaseSelectEntIndustry: '請選擇企業行業',
    pleaseSelectEntScale: '請選擇公司規模',
    pleaseInputCity: '請輸入城市',
    pleaseInputProvince: '請輸入省份',
  },
  VX_component: {
    faceRecognitionAuth: '人臉識別認證',
    clickSign: '點我打卡',
    remainSeconds: '剩餘{second}秒',
    faceRecognitionTipOne: '1.請確保您的真實姓名和身份證號碼正確無誤',
    faceRecognitionTipTwo: '2.確認是否有攝像頭或者是否已同意授權網站使用攝像頭？若無法使用，請登錄小程序或者APP進行人臉識別；',
    faceRecognitionTipThree: '3.人臉識別剩餘打卡次數<span>{remainCount}</span>次，當前已失敗<span>{currentErrorCount}</span>次，超出將不可繼續進行考試或學習，請知曉！',
    verifyIdentity: '驗證身份',
    validateSuccess: '驗證成功',
    signSuccess: '打卡成功',
    modifyInfo: '修改實名信息',
    realName: '真實名稱',
    realNameInputPlaceholder: '請輸入真實名稱',
    idCard: '身份證',
    idCardInputPlaceholder: '請輸入身份證號',
    signTip: '正在錄像！請不要轉頭或者把頭移出攝像頭裏',
    startAuth: '開始認證',
    resetTip: '請確定身份證號碼和姓名是否正確？',
    signConfirmTips: '<p style="text-align: left;margin-bottom: 30px">當前需要人臉認證，請先補全身份證號和姓名再來{typeContent}</p>',
    study: '學習',
    confirmTip: '人臉識別提示',
    confirmInput: '前往填寫',
    recognitionNoTimes: '您的人臉識別校正次數已用完，不可再進入{typeContent}。如有需要請聯繫管理員',
    errorTimesLimit: '今日人臉識別錯誤次數已經超出限制了，不可再進入{typeContent}。如有需要請聯繫管理員',
    confirm: '我知道了',
    signVideoUploadTip: '正在上傳打卡視頻，請不要關閉！',
    close: '關閉（{second}）',
    reAuth: '重新認證',
    confirmIdCardAndName: '請確定身份證號碼和姓名是否正確？',
    checkingCamera: '檢測攝像頭中...',
    validating: '正在驗證中，請勿離開此頁...',
    faceRecognitionOvertime: '人臉識別超時！',
    cameraAbnormal: '攝像頭異常...',
    cameraAbnormalTip: '請確認一下是否有攝像頭或者是否點擊同意授權網站使用攝像頭？若沒有攝像頭，請登錄小程序或者APP進行人臉識別？',
    errorTip: '錯誤提示',
    relogin: '重新登錄授權攝像頭',
    uploadPictureFailure: '上傳圖片出錯，請稍後重試',
    unfold: '展開',
    fold: '收起',
  },
  VX_error: {
    serviceError: '服務器錯誤',
    tipOne: '抱歉，服務器上發生錯誤',
    tipTwo: '此頁面可能有bug，請聯繫管理員！',
    tipThree: '光速返回',
  }
}

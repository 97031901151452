export default {
  notData: 'データなし',
  en: '英語',
  cn: '中国語(簡体字)',
  common: '合計',
  page: 'ページ',
  data: '件',
  confirm: '確認',
  name: '名前',
  and: 'と',
  pleaseInputName: '名前を入力してください',
  post: '職位',
  pleaseSelect: '選択してください',
  companyName: '企業名',
  pleaseInputCompanyName: '企業名を入力してください',
  entScale: '企業規模',
  address: '企業住所',
  entIndustry: '業界',
  entCity: '所在都市',
  more: 'もっと見る',
  changes: '換えてみる',
  integral: '単位',
  creditHour: 'ポイント',
  certificateCount: '証明書',
  stick: 'TOPに固定',
  sheet: '枚',
  point: '点',
  hitBooks: '受講へ',
  learnTask: '学習タスク',
  testTask: '試験タスク',
  assessmentTask: '評価タスク',
  coachTask: 'コーチングタスク',
  notStarted: '未実施',
  learning: '学習中',
  haveLearned: '学習済',
  waitTest: '受験待',
  pass: '合格',
  fail: '不合格',
  waitMark: '採点待',
  absent: '欠席',
  designate: '公開',
  recommend: 'おすすめ',
  hot: '人気',
  newest: '最新',
  uploadA: '一件アップロード',
  course: '研修',
  studyCourse: '研修',
  learnProject: 'コース',
  peopleLearn: '人が学習中',
  myTask: 'Myタスク',
  trainRecords: '研修履歴',
  myRecord: 'My履歴',
  trainOperation: '研修操作',
  myNews: 'Myメッセージ',
  setLanguage: '言語設定',
  inviteMembersJoin: 'メンバーを招待する',
  search: '検索',
  cancel: 'キャンセル',
  searchHistory: '履歴検索',
  emptyHistory: '履歴クリア',
  searchRecommended: 'おすすめの検索',
  loading: '読み込み中...',
  scrollBottom: 'すでに最下部に到達しました。これ以上の内容はありません',
  CheckOutMoreComments: '他のコメントを見る',
  checkMore: 'もっと見る',
  lineClass: 'eラーニング',
  graphicClass: 'テキスト',
  liveClass: 'ライブ',
  learnState: '学習状況',
  all: 'すべて',
  notLearn: '未学習',
  sort: 'ソート',
  default: 'デフォルト',
  latest: '最新',
  hottest: '人気',
  comments: 'コメント',
  giveLike: 'いいね',
  congratulation: 'おめでとうございます',
  registerSuccess: '登録に成功しました',
  jumpAfter: '後で自動的にページ遷移します',
  toLogin: 'ログインへ >',
  pleaseSelectCompany: '企業を選択してください',
  addCompany: '企業追加',
  chooseEnterpriseSuccess: '企業を選択しました',
  headPortrait: '顔写真',
  phone: '電話番号',
  changePassword: 'パスワード変更',
  Logout: 'ログアウト',
  unbounded: '未登録',
  editSuccess: '修正に成功しました',
  ranking: 'ランキング',
  score: '得点',
  realTimeDataUpdate: 'データはリアルタイムで更新されます',
  takingPictures: '写真撮影',
  choosePhonePhotoAlbum: '携帯電話のアルバムからアップロード',
  complete: '修了',
  corporateAnnouncements: '通知',
  learnMessage: '学習メッセージ',
  read: '既読',
  unread: '未読',
  pleaseInputPhone: '電話番号を入力してください',
  password: 'パスワード',
  pleaseInputPassword: 'パスワードを入力してください',
  code: '認証コード',
  pleaseInputCode: '認証コードを入力してください',
  getCode: '認証コードを取得する',
  codeLogin: '認証コードでログイン',
  passwordLogin: 'パスワードでログイン',
  forgetPassword: 'パスワードリセット',
  register: '企業登録',
  useAccountLogin: 'サードパーティのアカウントでクイックログイン',
  verifyMobileRegistered: '電話番号が登録されているか確認中...',
  cellPhoneNumberRegistered: '電話番号は既に登録されています',
  loginPassword: 'パスワード',
  checkPassword: 'パスワードの確認',
  pleaseEnterLoginPassword: 'パスワードを入力してください',
  pleaseEnterCheckPassword: 'パスワードを再入力してください',
  passwordLength: '{label}は{minLength}〜{maxLength}文字で入力してください',
  passwordRuleCheck: 'パスワードと確認のパスワードが一致しません',
  pleaseInputLoginPassword: '6〜16桁の英数字、記号の組み合わせのものを入力してください',
  graded: 'レイティング',
  beginLearn: '学習開始',
  continueLearn: '学習再開',
  courseware: '教材',
  lecturer: '講師',
  courseIntroduction: '研修紹介',
  courseCatalog: '研修目次',
  studentsComment: '受講者コメント',
  watchRecord: '視聴履歴',
  watchCourseware: '資料一覧',
  haveThumbUp: 'いいね済',
  hasBeenCancelled: 'キャンセル済',
  details: '詳細 >',
  introduce: '紹介',
  unfinished: '未修了',
  completed: '修了',
  overdue: '期限切れ',
  exam: '試験',
  time: '回',
  totalScore: '総点数',
  goal: '得点',
  notAvailable: 'なし',
  totalProgress: '全体進捗',
  periodValidity: 'タスク期限',
  qualifiedReward: '合格報酬',
  certificateQuality: '修了書',
  learnProcess: '学習進捗',
  testSchedule: '試験進捗',
  solidExerciseSchedule: '実技進捗',
  trainPurpose: '研修目的',
  completeJobEvaluation: '職位評価を修了する',
  eligibilityCriteria: '合格基準',
  credits: '単位',
  have: '',
  field: '回',
  pleaseSelectPost: '職位を選択してください',
  scale: '企業規模',
  pleaseSelectScale: '企業規模を選択してください',
  industry: '業界',
  pleaseSelectIndustry: '業界を選択してください',
  city: '所在都市',
  pleaseSelectCity: '所在都市を選択してください',
  synopsis: '概要',
  browse: '閲覧',
  playCount: '再生回数',
  allComments: 'すべてのコメント',
  pleaseInputComment: 'コメントを入力してください',
  taskDirectory: 'タスク目次',
  pleaseApply: 'この項目に申請してください',
  registerNow: '今すぐ申請',
  learnContent: '学習内容',
  originalClass: 'オリジナル研修',
  tip: '注意',
  deleteComment: 'このコメントを削除すると元に戻せません。よろしいですか',
  newEmployeeTask: '新入社員タスク',
  takeTask: 'オプションタスク',
  taskFile: 'タスクファイル',
  learnFile: '学習ファイル',
  certificationFile: '認証ファイル',
  learnRecord: '学習履歴',
  certificateRecord: '証明書履歴',
  courseRecord: '研修履歴',
  learnProjectRecord: 'コース履歴',
  companyTitle: '同僚が簡単に見つけられるように、真実の情報を入力してください。',
  registerSuccessTip: '読んで同意します',
  userLog: '『利用規約と『プライバシーポリシー』',
  loginSuccessUseLog: '読んで同意します',
  pleaseAgressLog: '『利用規約』と『プライバシーポリシー』を先に全部読んで同意してください。',
  useService: '『利用規約』',
  usePrivacy: '『プライバシーポリシー』',
  paper: '試験問題',
  pleaseInput: '入力してください',
  reply: '返信',
  to: '～',
  testTime: '試験時間',
  testDuration: '試験時間',
  passMark: '合格点数',
  residueDegree: '受験回数',
  unlimited: '制限なし',
  nothing: 'なし',
  examTips: '注意：試験が開始されると、カウントダウンが終了すると自動的に提出されます',
  enterTest: '受験へ',
  singleTopicSelection: '択一問題',
  multipleChoice: '複数選択問題',
  trueOrFalseQuestions: '正誤判断問題',
  fillsUpTopic: '穴埋め問題',
  shortAnswerQuestions: '記述問題',
  onTopic: '前の問題',
  nextQuestion: '次の問題',
  answerSheet: '回答用紙',
  papers: '提出',
  paperNoSaveTip: '未回答の問題があります。提出しますか？',
  paperSaveTip: '提出しますか？',
  didNotAnswer: '未回答',
  answered: '回答済',
  toLearn: '学習タスクに戻る >',
  toCourse: '研修に戻る >',
  finishExam: 'おめでとうございます！試験が完了しました。',
  finishGrade: '得点は',
  finishExamTip: '成績は客観問題の得点のみを含み、主観問題は講師による評価が必要です。以下の集計は客観問題のみを計算しています',
  finishSuccess: '正解（問）',
  finishError: '不正解（問）',
  finishTotalTime: '合計時間（分）',
  lookAtAnswer: '正答を見る',
  answer: '正答',
  parsing: '解説',
  examinationSite: '出題ポイント',
  difficulty: '難易度',
  simple: '簡単',
  general: '一般',
  moreDifficult: 'やや難しい',
  difficult: '難しい',
  pleaseInputEmpty: '',
  pleaseInputContent: '目の空欄の回答を入力してください',
  pleaseInputAnswer: '回答を入力してください',
  learnStartEndTime: '学習可能の時間を超えています',
  nextStep: '次へ',
  answerError: '不正解',
  answerSuccess: '正解',
  totalCredit: '合計単位',
  getCertificate: '証明書取得',
  taskCompletionRate: 'タスク修了率',
  overdueRateTask: 'タスク期限切れ率',
  timedTask: '定時タスク',
  totalCredits: '合計単位',
  toadyGet: '本日取得',
  pleaseInputSearch: '検索内容を入力してください',
  back: '戻る',
  paperNeedTo: 'この試験は',
  paperFinish: '内に終了する必要があります',
  passTitle: '合格',
  passComplete: '修了',
  goToCommunity: 'コミュニティへ',
  createName: '作成者',
  examWillBegin: '試験開始',
  continueTest: '試験再開',
  progress: '進捗',
  operation: '操作',
  examinationCompleted: '試験が完了しました',
  correct: '正解',
  error: '不正解',
  PersonalIntroduction: '自己紹介',
  noIntroduction: '紹介なし',
  pleaseEnterContent: '内容を入力してください',
  returnHome: 'ホームページに戻る',
  noLearn: '先に申請してください',
  technicalPost: '職位名称',
  bindPhone: '電話番号と紐付ける',
  reExamination: '再受験',
  registrationEnterpriseInformation: '企業情報登録',
  returnLogin: 'ログインに戻る',
  pleaseInputChangePassword: 'パスワードを再入力してください',
  passwordNotCheck: 'パスワードが一致していません',
  codeError: '認証コードが間違っています',
  accountRegister: 'このアカウントはすでに存在しています',
  in: 'クリックして入る',
  pleaseInputMsg: '以下の情報を入力してください',
  directory: '目次',
  courseExam: 'アフターテスト',
  pleaseLearn: 'この内容を再度学習してください',
  cardPlay: '打刻をクリックしてください',
  computedPlayCard: '打刻してから学習を続けてください',
  learnSuccess: '修了とみなす',
  noUpdate: '更新なし',
  free: '無料',
  no: '第',
  chapter: '章',
  chapterNumber: '章節',
  dataLoadError: 'データの読み込みに失敗しました。後でもう一度試してください。',
  videoNoUpdate: 'この動画は更新されていません',
  bugWatch: '購入してから視聴してください',
  CreditRecord: '単位取得履歴',
  CertificateRecords: '証明書履歴',
  CourseRecord: '研修履歴',
  LearningProjectRecord: 'コース履歴',
  startDate: '開始日',
  endDate: '終了日',
  export: '出力',
  examRemark: '試験前の説明',
  CreditsContent: '単位内容',
  creditBehavior: '単位行為',
  creditOrigin: '単位取得先',
  timer: '時間',
  batchDelete: '一括削除',
  batchRead: '既読にする',
  lookDetail: '詳細を見る',
  pleaseSelectNotice: '通知を選択してください',
  sureDeleteNotice: '選択された通知を一括削除しますか',
  deleteError: '削除に失敗しました！',
  actionError: '操作に失敗しました！',
  sureReadNotice: '通知を一括で既読にしますか？',
  courseName: '研修名',
  updateTime: '更新時間',
  status: '状態',
  getCredits: '取得単位',
  taskName: 'タスク名',
  startTime: '開始時間',
  taskDeadline: 'タスク期限',
  taskType: 'タスクタイプ',
  progressAchievement: '進捗/成績',
  beforeDay: '日前',
  beforeHour: '時間前',
  beforeMinutes: '分前',
  sureDeleteComment: 'このコメントを削除しますか？',
  deleteSuccessComment: 'コメントの削除に成功しました！',
  beforeLoginComment: 'ログインしてからコメントしてください',
  commentSuccess: 'コメントに成功しました！',
  commentError: 'コメントに失敗しました！',
  courseType: '研修タイプ',
  coursewareNumber: '教材数',
  loginLearn: 'ログインして学習へ',
  courseRemove: 'この研修は廃止されました',
  loginWatch: 'ログインして視聴へ',
  addCar: 'カートに追加する',
  ImmediateSettlement: 'すぐ清算へ',
  SharingMakes: 'シェアして収益を得る',
  inviteFriendsLearn: '友達を招待して学習する',
  inviteEveryOne: '招待するごとに',
  inviteLink: '招待リンク',
  copyLink: 'リンクをコピー',
  AuthorizationPurchase: '購入を許可する',
  pleaseInputPurchaseNumber: '購入する許可の数を入力してください',
  loginRate: 'ログインしてからレーティングしてください',
  rateSuccess: 'レーティングに成功しました',
  loginShare: 'ログインしてから共有収益がもらえます',
  copySuccess: 'コピーに成功しました',
  loginBuy: 'ログインしてから購入許可に参加してください',
  loginAddCar: 'ログインしてからカートに追加してください',
  loginLike: 'ログインしてからいいねしてください',
  likeSuccess: 'いいねに成功しました！',
  cancelLike: 'いいねをキャンセルしました！',
  courseRate: '研修のレーティングは1から5までの範囲です！',
  learnStudyNum: '受講人数',
  collectionNum: 'お気に入り人数',
  collection: 'お気に入り',
  loginCollection: 'ログインしてからお気に入りに追加してください',
  cancelCollection: 'お気に入りをキャンセルしました',
  collectionSuccess: 'お気に入りに追加しました',
  applyRegistration: '申請',
  applySuccess: '申請が修了しました。承認結果を待ってください！',
  applyRegistrationError: '申請が却下されました！',
  applyRegistrationSuccess: '申請が承認されました！',
  registrationApproval: '承認中',
  registrationFailed: '却下',
  pleaseLogin: 'ログインしてください！',
  submitSuccess: '提出に成功しました',
  pleaseDeleteComment: 'この操作はコメントを完全に削除します。続行しますか？',
  BreakDown: '詳細解説',
  pay: '支払',
  nexPage: '次のページ',
  returnFirst: '最初のページに戻る',
  sureExport: 'データを出力しますか？',
  fileName: 'ファイル',
  clearLog: '履歴クリア',
  oldPassword: '旧パスワード',
  pleaseInputOldPassword: '旧パスワードを入力してください',
  newPassword: '新パスワード',
  pleaseInputNewPassword: '新パスワードを入力してください',
  repeatNewPassword: '新パスワード再入力',
  pleaseInputRepeatNewPassword: '新パスワードを再入力してください',
  pleaseInputSuccessPassword: '正しいパスワードを入力してください',
  pleaseInputSuccessNewPassword: '正しい新パスワードを入力してください',
  pleaseInputSuccessRepeatPassword: '正しい確認パスワードを入力してください',
  pleaseInputSuccessLengthPassword: 'パスワードは8〜30文字にしてください',
  passwordNoSure: '確認パスワードが新パスワードと一致しません',
  pleasePwdSizeRule: '8〜30文字の英数字、記号の組み合わせのものを入力してください',
  passwordRule: 'パスワードは大文字と小文字のアルファベット、記号、数字を含める必要があります',
  sureClearLog: '現在の履歴をクリアしますか',
  ongoing: '実施中',
  permanently: '期限なし',
  month: 'ヶ月',
  pending: '承認待',
  certificationName: '認証名',
  completedStudy: '学習済',
  pleaseInputCertificate: '証明書名',
  automaticIssued: '自動発行',
  manualIssued: '手動発行',
  sortIndex: '番号',
  certificateName: '証明書名',
  userName: '実名',
  certificateNumber: '証明書番号',
  certificateOrigin: '証明書取得先',
  acquisitionTime: '取得日時',
  issueWay: '発行方法',
  statusCertificate: '証明書状況',
  outDate: '期限切れ',
  hold: '保有中',
  loginSuccess: 'ログインに成功しました',
  noCompany: '企業に登録されていません',
  refresh: 'リフレッシュ',
  notYetStart: '未開始',
  examRecordTitle: '受験履歴',
  canResit: '再受験可能',
  AnswerTime: '受験時間',
  paperTotal: '試験総点数',
  answerHour: '回答時間',
  examResult: '試験結果',
  readerUser: '採点者',
  detail: '詳細',
  submitTime: '提出時間',
  answerTime: '回答時間',
  evaluation: '評定',
  haveExam: '受験済',
  total: '合計',
  pages: 'ページ',
  count: '件',
  notReadPaper: '解説を表示できません',
  screenCuttingNotAllowed: '本試験では画面の切替は許可されていません。画面の切替後、自動的に提出されます。',
  screenCutTipsOne: '画面切替回数が',
  screenCutTipsTwo: '回に超えると自動的に試験を提出されます',
  screenCuttingTimesExceedsLimit: '画面切替回数が制限を超えました。自動的に提出されます。',
  examTimeOver: '試験時間が終了しました',
  remark: 'コメント',
  applyTip: '学習できません。承認をお待ちください',
  applyTip1: '学習できません。まず申請してください',
  rate: {
    one: '勉強にならなかった',
    two: 'ちょっと勉強になった',
    three: 'そこそこ勉強になった',
    four: 'かなり勉強になった',
    five: 'めちゃくちゃ勉強になった',
  },
  login: {
    DingTalk: 'Ding Talk',
    pleaseLoginDingTalk: 'Ding Talkでスキャンしてログインしてください',
    EnterpriseWeChat: '企業WeChat',
    pleaseLoginEnterpriseWeChat: '企業WeChatでスキャンしてログインしてください',
    toLogin: 'アカウントでログイン',
    toNo: 'IDでログイン',
    pleaseInputPhone: '電話番号を入力してください',
    pleaseInputNo: 'IDを入力してください',
    pleaseInputCode: '認証コードを入力してください',
    sendCode: '認証コードを取得する',
    pleaseInputPassword: 'パスワードを入力してください',
    login: 'ログイン',
    passwordLogin: 'パスワードでログイン',
    codeLogin: '認証コードでログイン',
    forgetPassword: 'パスワードリセット',
    vaildatePhone: '電話番号を認証する',
    setNewPsd: 'パスワードを変更する',
    setSuccess: '変更に成功しました',
    register: '登録',
    pleaseInputSuccessPhone: '正しい電話番号を入力してください',
    pleaseSetPassword: 'パスワードを設定してください',
    pleaseInputInfo: '以下の情報を入力してください',
    pleaseInputCheckPassword: 'パスワードを再入力してください',
  },
  home: {
    admin: '管理画面',
    sign: 'デイリー出席確認',
    task: 'Myタスク',
    change: '企業切替',
    contribute: 'My貢献',
    certificates: 'My証明書',
    approval: '実技宿題の承認',
    credit: '単位(単位)',
    certificateCount: '証明書(枚)',
    integrals: 'ポイント(ポイント)',
    changeLang: '言語切替：',
    logout: 'ログアウト',
    more: 'もっと見る',
    credits: '単位',
    integral: 'ポイント',
    certificate: '証明書',
    toLearn: '学習へ',
    point: 'ポイント',
    part: '枚',
    recommended: 'おすすめ',
    hot: '人気',
    latest: '最新',
    notLearn: '未学習',
    inStudy: '学習中',
    learnComplete: '学習修了',
    learnProgram: 'コース',
    lineClass: 'eラーニング',
    graphicClass: 'テキスト',
    assigned: '公開',
    study: '人が学習中',
    waitExam: '受験待',
    passExam: '合格',
    notPassExam: '不合格',
    readExam: '採点待',
    notExam: '欠席',
    uploadType: '一件アップロード',
    course: '研修',
    learningProjects: 'コース',
    changeCourse: '換えてみる',
    learningTask: '学習タスク',
    examTask: '試験タスク',
    assessmentTask: '評価タスク',
    coachTask: 'コーチングタスク',
    top: 'TOPに固定',
    rank: '順位',
    name: '名前',
    logoSize: 'サイズ制限：5M以下',
  },
  upload: {
    clear: 'アップロードキャンセル',
    clearTip: 'アップロードをキャンセルしますか？',
    uploadtTip: 'アップロード中',
    uploadtTip2: 'アップロード形式は画像、Word、PDFのみです！',
    resetUpload: '再アップロード',
    download: 'ダウンロード',
    seek: '閲覧',
    close: '閉じる',
    attachment: '添付ファイルアップロード',
  },
  VX_account: {
    newestStudyTime: '最終学習時間',
    projectName: 'コース名',
    trainingRequirement: '研修要件',
    trainingType: '研修種類',
    trainingForm: '研修形式',
    trainingPurpose: '研修目的',
    planTheNumberofPeople: '計画参加者数',
    estimatedCost: '予定費用',
    estimatedClassHours: '予定授業時間',
    trainingOrgLec: '研修機構/講師',
    trainingObject: '研修対象',
    trainingYear: '研修年度',
    trainingQuarter: '研修四半期',
    trainingMonth: '研修月',
    trainingAddress: '研修場所',
    executiveDepartment: '実施部署',
    superintendent: '責任者',
    executor: '担当',
    selectMember: '参加者選択',
    determine: '確定',
    createTrainingRequirement: '研修要件作成',
    trainingRequirementInputPlaceholder: '研修要件を入力してください',
    trainingYearSelectPlaceholder: '研修年度を選択してください',
    trainingQuarterSelectPlaceholder: '研修四半期を選択してください',
    trainingMonthSelectPlaceholder: '研修月を選択してください',
    superintendentSelectPlaceholder: '責任者を選択してください',
    planName: '計画名',
    source: '情報源',
    creatorName: '作成者',
    gmtCreate: '作成時間',
    relatedTask: '関連タスク',
    trainingTimePeriod: '研修時間',
    completionRate: '修了率',
    passRate: '合格率',
    moreOperations: 'その他',
    execute: '実施',
    executionDetails: '実施詳細',
    edit: '編集',
    editMonthlyPlans: '月次計画編集',
    expireTime: '期限時間',
    certificateView: '証明書詳細',
    downloadPicture: '画像ダウンロード',
    certificateNo: '証明書番号',
    issueTime: '発行日',
    certificateTime: '有効時間',
    certificateeffectiveStartTime: '有効期限',
    obtainType: '取得方法',
    autoIssue: '自動発行',
    manuIssue: '手動発行',
    issueSource: '発行元',
    issueCause: '発行理由',
    quashCause: '取り消し理由',
    todayCount: '本日の取得単位',
    totalCount: '累計取得単位',
    type: '種類',
    changeTime: '変更時間',
    backToLearn: '学習に戻る',
    backToExamTask: '試験タスクに戻る',
    inEffect: '有効',
    rescinded: '取り消し',
    indefiniteTime: '無期限',
    productName: '商品名',
    exchangeCount: '交換数',
    consumptionIntegral: '消費ポイント',
    exchangeTime: '交換時間',
    issueProtectTime: '発行時間',
    todayIntegralTotal: '本日累計取得単位',
    integralTotal: '累計ポイント',
    integralRules: 'ポイントルール',
    integralUsable: '利用可能なポイント',
    toExchangeProduct: 'ポイント交換',
    courseInteraction: 'コースインタラクション',
    contribution: '貢献',
    integralBehavior: 'ポイント行為',
    operationName: '操作名',
    singleScore: '単一評価ポイント',
    upperIntegralDay: '一日中ポイントの上限',
    noUpperIntergral: '無制限',
    validityPeriod: '有効期間',
    integralRulesTips: '注意',
    integralRulesTipOne: '累計ポイント = 取得したポイントの合計 - 抵価コンテンツなどによって差し引かれたポイント',
    integralRulesTipTwo: 'ユーザはポイントを使用して、対応するページで商品を交換することができます。交換要件や使用規則などの詳細については、具体的にはページの表示に従ってください',
    integralRulesTipThree: '在庫数の制限により、ポイント商品は在庫がなくなり次第、交換終了となります。先着順ですので、先に交換した方が優先されます',
    notFinish: '未終了',
    finished: '終了',
    settled: '清算済',
    taskNamePlaceholder: 'タスク名で検索',
    offlineClassName: 'オフライン研修名',
    classStartTime: '開講時間',
    courseAddress: '開講場所',
    trainingAttachment: '研修添付ファイル',
    courseEvaluate: '研修を評価する',
    evaluateCourse: '研修への評価',
    questinonaireModelCancel: 'キャンセル',
    questinonaireModelDetermine: '確定',
    lecturerEvaluate: '講師を評価する',
    evaluateLecturer: '講師への評価',
    totalEvaluateCount: '評価必要な人数',
    waitEvaluateCount: '評価待の人数',
    completeEvaluateCount: '評価済の人数',
    evaluateTrainees: 'ユーザにレーティングする',
    evaluateTrainee: 'ユーザへの評価',
    questionaireEvaluate: 'アンケート評価',
    remove: '削除',
    announcement: '通知',
    announcementTitle: '通知のタイトル',
    userMyEvaluate: 'My評価',
    userEvaluateMe: '私への評価',
    traineesEvaluate: 'ユーザからの評価',
    lecturerEvaluateMe: '講師からの評価',
    commitPracticeTask: '実技宿題の提出',
    taskRequirement: '宿題要件',
    attachment: '添付ファイル',
    step: '第{index}ステップ',
    pracDialogPlaceholder: 'ステップを書いてください(オプション)',
    pracDialogTips: '画像、ドキュメント、圧縮ファイルの添付が可能です。最大5件まで可能です',
    addStep: 'ステップ追加',
    saveDraft: '下書き保存',
    commit: '提出',
    submitWarning: '内容を入力してください',
    startSurvey: 'アンケート開始',
    statisticsResult: '集計結果',
    selfEvaluate: '自己評価',
    othersEvaluate: '他者評価',
    awardCredit: 'ポイント授与',
    repeatedExam: '再試験',
    examStatics: '試験データ',
    waitEvaluation: '評定待',
    allMessage: 'すべてのメッセージ',
    unreadMessage: '未読メッセージ',
    readMessage: '既読メッセージ',
    userMsgPlaceholder: 'メッセージ種類',
    messageName: 'メッセージ名',
    messageType: 'メッセージ種類',
    messageDetails: 'メッセージ詳細',
    close: '閉じる',
    batchReadTip: '選択された{length}件のメッセージを既読にしますか？',
    batchDeleteTip: '選択された{length}件のメッセージを削除しますか？',
    deleteTip: '削除アラート',
    attendanceManagement: '出席管理',
    attendanceName: '出席名称',
    relatedOfflineClass: 'オフライン研修とリンク',
    sourceName: '情報源名',
    signIn: '出席',
    signOut: '退席',
    attendanceQRCode: '出席管理QRコード',
    scoreRecord: 'ポイント履歴',
    exchangeRecord: '交換履歴',
    effectiveStatusPlaceholder: '証明書の状態',
    startDatePlaceholder: '開始時間',
    endDatePlaceholder: '終了時間',
    issueStatusPlaceholder: '発行状態',
    notIssue: '発行待',
    issued: '発行済',
    surveyTask: 'アンケートタスク',
    evaluationTask: '評価タスク',
    trainingPlan: '研修計画',
    evaluationTaskOfNamePlaceholder: 'タスク名で検索',
    yearPlaceholder: '研修年度',
    quarterPlaceholder: '研修四半期',
    monthPlaceholder: '研修月',
    planOfNamePlaceholder: '計画名で検索',
    uploadTips: 'クリックしてアップロード',
    executorSelect: '担当選択',
    endExamTip: '試験を終了しますか？',
    handInExamPaper: '試験を提出しますか？',
    autoHandInExamPaper: '画面の切替が許可されていません、自動的に試験を提出しています',
    trainRecords: '研修履歴',
    basicInfo: '基本情報',
    taskTitlePlaceholder: 'タスク名を入力してください',
    classification: '所属カテゴリ',
    validityTime: '有効期間',
    startTimePlaceholder: '開始時間',
    endTimePlaceholder: '終了時間',
    departmentName: '組織',
    costBudget: '費用予算',
    yuan: '元',
    stageTask: 'フェーズタスク',
    studyMode: '学習モード',
    notSupportOfflineTips: 'ロック解除モードではオフライン研修には対応していません',
    freeMode: 'フリーモード',
    unlockMode: 'ロック解除モード',
    stageContentSetting: 'フェーズ内容設定',
    stage: 'フェーズ{index}',
    stageName: 'フェーズ名',
    pleaseInputStageName: 'フェーズ名を入力してください',
    stageExplanation: 'フェーズ説明',
    unlockTimePlaceholder: 'ロック解除時間',
    stageContent: 'フェーズ内容',
    name: '名称',
    contentDuration: '内容時間',
    nothaveDuration: '時間なし',
    pleaseSelectUnlockTime: 'ロック解除時間を選択してください',
    changeResource: 'リソースを換える',
    delete: '削除',
    drag: 'ドラッグ',
    withinStageThroughLevel: 'フェーズ内でロック解除',
    betweenStageThroughLevel: 'フェーズ間でロック解除',
    unlockTaskTipOne: '有効にすると、ユーザはこのフェーズ内で順番に学習内容を解除する必要があります',
    unlockTaskTipTwo: '有効にすると、ユーザは各フェーズ内で順番に学習内容を解除する必要があります。（例、フェーズ1を修了しないと、フェーズ2の内容を学習できません）',
    progressSync: '進捗同期',
    progressSyncTip: '追加された研修・素材の進捗をシステム全体で同期する<br/>同期機能：再生時に進捗を同期する',
    synchronize: '同期',
    notSynchronize: '同期しない',
    estimatedTaskHours: '推定学習時間',
    estimatedTaskHoursStandards: '推定学習時間の基準：<br/>ビデオ：動画の長さに応じた統計<br/>音声： 音声の再生時間に応じた統計<br/>教材、研修の音声とビデオ<br/>の再生時間、オフライン研修の開始時刻と継続時間など、その他のコンテンツは研修の推定<br/>統計に含まれません',
    assignRange: '公開範囲',
    assignRangeSetting: '公開範囲設定',
    select: '選択',
    selectedObject: '選択された対象',
    selectedPerson: '選択されたユーザ',
    qualifiedSetting: '合格設定',
    qualifiedStandardTip: 'ユーザがコース全体を合格する基準',
    taskProgressReach: '学習進捗達成率',
    examPass: '試験合格数',
    practicePass: '実技宿題通過数',
    qualifiedRewardTip: 'ユーザがコースの合格報酬取得の基準',
    getCertificate: '合格後証明書の取得',
    otherSetting: 'その他の設定',
    learningTimeSetting: 'ドキュメント学習時間の設定',
    learningTimeTip: '教材学習必須時間',
    documentLearningTime: 'ドキュメント学習時間',
    secondsPerPage: '秒/ページ',
    learningTimeSettingTips: '1.設定されていない場合、ユーザのドキュメント学習進捗は閲覧ページ数で計算され、学習期間には含まれません。<BR>2 .設定後、ユーザの学習進捗は学習時間で計算され、学習時間の最大値は1ページ当たりの学習時間かけるドキュメントのページ数となります',
    faceRecognition: '顔認証',
    faceRecognitionTip: '有効にすると、ユーザは各フェーズの学習に入る前に顔認証を通過する必要があります',
    save: '保存',
    publish: '公開',
    pleaseSelectAssignRange: '公開範囲を選択してください',
    pleaseSelectEligibilityCriteria: '合格基準を選択してください',
    pleaseSelectClassification: '所属カテゴリを選択してください',
    pleaseInputValidityPeriod: '有効期間を入力してください',
    pleaseInputTaskName: 'タスク名を入力してください',
    pleaseSelectStageContent: 'フェーズ内容を選択してください',
    selectAssignRange: '公開範囲を選択してください',
    firstStage: '第一フェーズ',
    delTip: '【{name}】を削除しますか。削除後、関連する設定内容も一緒に削除されますので、ご注意ください',
    changeFile: 'ファイル切替',
    selectFile: 'ファイル選択',
    changeOnlineCourse: 'eラーニング切替',
    changeGraphicCourse: 'テキスト切替',
    changeLiveCourse: 'ライブ切替',
    selectOnlineCourse: 'eラーニング選択',
    selectGraphicCourse: 'テキスト選択',
    selectLiveCourse: 'ライブ選択',
    addExamination: 'テスト追加',
    editExamination: 'テスト編集',
    addHomework: '実技宿題追加',
    editHomework: '実技宿題編集',
    addOfflieCourse: 'オフライン研修追加',
    editOfflieCourse: 'オフライン研修編集',
    addAttendance: '出席追加',
    editAttendance: '出席編集',
    addInvestigate: 'アンケート追加',
    editInvestigate: 'アンケート編集',
    deletePhraseTip: '削除すると、すべての割り当てられたタスクにも削除され、タスクの進捗が再計算されます。この操作は取り消せません。削除しますか？',
    selectMembers: '公開範囲を選択してください',
    selectCertificate: '証明書選択',
    stageTaskTip: 'フェーズ内容は空にできません',
    stageContentTip: '内のフェーズ内容は空にできません',
    assignCount: '公開人数',
    participationCount: '参加人数',
    completeCount: '修了人数',
    completeRate: '完成率(%)',
    passRatePercent: '合格率(%)',
    unbind: 'リンク解除',
    publishSuccess: '公開に成功しました',
    unbindTip: 'リンクを解除すると、タスクと計画は関連つけられなくなります。解除しますか',
    deleteDetailTip: '削除しますか？',
    modifierName: '提出者',
    planDetails: '計画詳細',
    expectTrainingMonth: '予定研修月',
    assignor: '指定者',
    beyondPeriod: 'タスクは有効期限を超過しましたので、学習できません',
    notLive: '未開始',
    liveHasEnd: 'ライブは終了しました、動画生成中です',
    videoGenerating: '動画生成中',
    totalPoint: '合計',
    essayPoint: '作文の得点',
    month: '月',
    integralTypePlaceholder: 'ポイント種類',
    sheet: '{count}枚',
    reLearn: '再学習',
    announcementTotal: 'すべての通知',
    announcementUnread: '未読の通知',
    announcementRead: '既読の通知',
    examExpired: '試験が有効期限に過ぎました！',
    inputAnswerPlaceholder: '{index}目の空欄の回答を入力してください',
    finishInTime: '{count}内に試験を完了してください',
    treeName: '保存場所',
    fileNameSearchPlaceholder: 'ファイル名で検索',
    currentPageData: 'すべてのページ',
    selectCountAtMost: '最大{count}枚が選択可能です',
    selectContentPlaceholder: 'データを選択してください',
    resourceClassification: 'リソース分類',
    courseNameSearchPlaceholder: '研修名で検索',
    originalOrNotPlaceholder: '内容種類',
    original: 'オリジナル',
    unoriginal: 'オリジナルではない',
    selectCourse: '研修選択',
    pleaseSelectCourse: '研修を選択してください',
    quotePaper: '試験問題',
    examName: 'テスト名',
    passLine: '合格ライン',
    passScore: '合格点',
    examTotalScore: '試験総点数',
    examDurationTip: '時間は300分を超えてはいけません',
    examDescription: '試験説明',
    examSetting: '試験設定',
    facialRecognitionTip: '有効にすると、モバイル端末で学習する際、学習に入る前に顔認証を通過する必要があります',
    unlimited: '制限なし',
    suspendAfterPassing: '合格後終了',
    customTimes: '回数指定',
    gradeSetting: '得点設定',
    recordLatestContent: '最新データを記録する',
    recordHighestContent: '最高得点を記録する',
    switchScreenCount: '画面切替回数',
    switchScreenTip: '画面切替の防止を設定すると、PC端末から穴埋め問題と記述問題を回答する際、画像のアップロードができません',
    notAllowed: '許可しない',
    custom: 'カスタム',
    answerOrder: '回答順番',
    answerOrderTipOne: '問題シャフル：ランダムで問題の順番をシャフルに設定する',
    answerOrderTipTwo: '選択肢シャフル：ランダムで問題の選択肢をシャフルに設定する',
    questionOutOfOrder: '問題シャフル',
    optionOutOfOrder: '選択肢シャフル',
    viewSetting: 'ユーザ表示設定',
    display: '表示',
    hide: '非表示',
    viewAnswerDetails: '回答詳細を見る',
    afterMarking: '採点後',
    afterPassing: '合格後',
    examEnd: '試験終了',
    invisible: '閲覧不可',
    viewContent: '内容確認',
    viewContentTypeOne: '正解可否と回答解説',
    viewContentTypetTwo: '正誤のみ表示',
    knowledgePoint: '知識ポイント',
    viewRank: 'ランキングを見る',
    afterHandingInPaper: '提出後',
    markingSetting: '採点設定',
    markingType: '採点方法',
    markingTypeOne: '自動採点',
    markingTypeTwo: '手動採点',
    saveAndAdd: '保存して追加を続ける',
    selectPaperPlaceholder: '試験を選択してください',
    selectPaper: '試験選択',
    selectMarkingUserPlaceholder: '採点者選択',
    addSuccess: '追加に成功しました',
    homeworkName: '宿題名',
    homeworkNamePlaceholder: '簡単に説明してください',
    taskRequirementPlaceholder: '実技の基準要件を入力してください',
    attachmentTip: 'アップロードされた添付ファイルが宿題の参考として、ユーザに公開されます',
    fileName: 'ファイル名',
    homeworkType: '宿題種類',
    homeworkTypeOne: '汎用宿題',
    homeworkTypeTwo: '新入社員宿題',
    homeworkTypeSelect: '種類はユーザには表示されません、「実技レポート」の絞り込みでのみ使用されます',
    selectAuditUser: '承認者選択',
    courseDuration: '研修時間',
    confirmCourse: '講師が研修スケジュールを確認する',
    notComputeCourseCost: '授業料無料',
    contentDescriptionPlaceholder: '詳細のコース紹介と研修目的を書いてください',
    moreSetting: 'その他の設定',
    lecturerEvaluateTrainees: '講師がユーザを評価する',
    start: '開く',
    directEvaluation: '直接レーティングする',
    questionnaire: 'アンケート調査',
    selectQuestionnaire: 'アンケート選択',
    fullMarks: '満点',
    traineesEvaluateLecturer: 'ユーザが講師を評価する',
    evaluationUseStar: '星評価',
    traineesEvaluateCourse: 'ユーザが研修を評価する',
    offlineExamination: 'オフライン評価',
    offlineExaminationTipOne: '有効にすると、ユーザの成績の登録ができます',
    offlineExaminationTipTwo: '有効にすると、出席管理者と管理者がユーザの成績の登録ができます',
    messageRemind: 'メッセージ通知',
    beforeTrainingStart: '研修開始',
    remindLecturer: '講師を通知する',
    remindStudent: 'ユーザを通知する',
    remindTip: '分前に通知を送る',
    other: 'その他',
    allowUpload: 'イベント写真などの添付ファイルのアップロードを許可する',
    pleaseSelectLecturer: '講師を選択してください',
    selectLecturer: '講師',
    relatedOfflineClassTip: 'オフライン研修とリンクしたら、該当出席がリンクされるオフライン研修にのみ有効です',
    offlineSelectPlaceholder: 'オフライン研修選択',
    attendanceTimeLimit: '出席時間制限',
    detailedAddress: '住所詳細',
    attendanceEffectiveRange: '出席の有効範囲',
    meter: 'メートル',
    addAttendanceAddress: '出席場所を追加する',
    dynamicQRCode: '動的QRコード',
    dynamicQRCodeTip: '有効にすると、QRコードのリフレッシュ頻度は10秒ごとになります',
    remindAttendanceManager: '出席管理者を通知する',
    beforeAttendance: '出席開始',
    reminderMinute: '分前に通知する',
    attendanceTimeLimitTip: '出席時間制限を選択してください',
    signInTimeTip: '出席時間を選択してください',
    signOutTimeTip: '退席時間を選択してください',
    selectUser: '責任者を選択してください',
    pleaseSelectQuestionnaire: 'アンケートを選択してください',
    investigationName: 'アンケート名',
    searchContent: '検索内容',
    user: 'ユーザ',
    noAuthTipOne: 'この部署の管理権限がありません。権限のある子部署のメンバーを選択してください',
    noAuthTipTwo: 'この部署の管理権限がありません。権限のある子部署のメンバーを選択してください',
    empNo: 'ID',
    noContent: '内容が見つかりませんでした',
    selectedUser: '選択されたユーザ',
    pleaseSelectUser: '追加する対象/ユーザを選択してください。',
    paperNameSearchPlaceholder: '試験名で検索',
    create: '作成',
    addressInputPlaceholder: '場所を入力して検索',
    pleaseSelectAddress: '場所を選択してください',
    classificationCatalogue: 'カテゴリ目次',
    questionnaireName: 'アンケート名',
    certificateSearchPlaceholder: '証明書名で検索',
    department: '部署',
    position: '職位',
    selectedUsers: '選択済みユーザ/ユーザ',
    lecturerOrTagSearchPlaceholder: '講師またはタグで検索',
    lectured: '授業済',
    yes: 'はい',
    no: 'いいえ',
    goToDetailTip: 'モバイル端末へ',
    known: '了解しました',
    submitTime: '提出時間',
    passScores: '合格点',
    importantCourse: '重要研修',
    attendanceNamePlaceholder: '出席名称で検索',
    startOnLive: 'ライブ時間',
    signInQrCode: '出席QRコード',
    signOutQrCode: '退席QRコード',
    refreshQrCode: 'QRコード刷新',
    relatedCourse: '関連コース',
    attendanceAddress: '出勤場所',
    attendanceType: '出勤方法：QRコードスキャン（出勤場所{range}米以内）',
    svgList: {
      materialSvg: '素材',
      onlineSvg: 'eラーニング',
      graphicSvg: 'テキスト',
      liveSvg: 'ライブ',
      examSvg: '試験',
      homeworkSvg: '実技宿題',
      offlineSvg: 'オフライン研修',
      checkinSvg: '出席',
      investigateSvg: 'アンケート',
    },
    trainingTypeEnum: {
      online: 'オンライン',
      offline: 'オフライン',
      mixed: 'ミックス',
      other: 'その他の研修',
    },
    trainingMonthEnum: {
      January: '1月',
      February: '2月',
      March: '3月',
      April: '4月',
      May: '5月',
      June: '6月',
      July: '7月',
      August: '8月',
      September: '9月',
      October: '10月',
      November: '11月',
      December: '12月',
    },
    postStatusEnum: {
      unpublished: '未公開',
      published: '公開済',
      reject: '却下',
      inApproval: '承認中',
    },
    ruleTypeEnum: {
      courseInteraction: '研修インタラクション',
      contribution: '貢献',
      attendance: '出席',
    },
    operateSceneEnum: {
      operateSceneOne: '一回の研修レーティングを完了する(eラーニング、コース)',
      operateSceneTwo: '一回の研修にいいねをする(eラーニング、コース)',
      operateSceneThree: '一回の研修にコメント/返信をする(eラーニング、コース)',
      operateSceneFour: '一回のオンライン授業を完了する(コース)',
      operateSceneFive: '一回のオンライン授業を完了する(eラーニング)',
      operateSceneSix: '一回のオフライン授業を完了する(オフライン研修)',
      operateSceneSeven: '一回のオンライン授業を完了する(ライブ)',
      operateSceneEight: '30分以内の遅刻/早退',
      operateSceneNine: '30分を超える遅刻/早退、無断欠席',
      operateSceneTen: '欠席(オフライン研修)',
    },
    validityPeriodTypeEnum: {
      disposable: '一回限り',
      everyDay: '毎日',
    },
    studyStatusEnums: {
      notLearned: '未実施',
      learning: '実施中',
      ended: '終了',
    },
    trainingQuarterEnum: {
      firstQuarter: '第一シーズン',
      secondQuarter: '第二シーズン',
      thirdQuarter: '第三シーズン',
      fourthQuarter: '第四シーズン',
    },
    courseTypeEnum: {
      video: 'eラーニング',
      imageText: 'テキスト',
      live: 'ライブ',
      offline: 'オフライン研修',
    },
    changeResourceTypeEnums: {
      vodOnline: 'eラーニング',
      imageText: 'テキスト',
      studyProject: 'コース',
      studyTask: '学習タスク',
      examTask: '試験タスク',
      postMap: 'マップ',
      evaluationTask: '評価タスク',
      postMapPath: 'マップルート',
    },
    changeCategoryEnum: {
      courseInteractive: 'コースインタラクション',
      contribution: '貢献',
      attendance: '出席',
      lowValueInteraction: '抵価インタラクション',
      lowValueContent: '抵価内容',
      integralExchange: 'ポイント交換',
      integralClearing: 'ポイントクリア',
    },
    taskTypeEnums: {
      studyProject: 'コース',
      studyTask: '学習タスク',
      examTask: '試験タスク',
      timeExam: '定時試験',
      external: '外部',
      manualIssue: '手動発行',
      postCertification: '職位認証',
      evaluationTask: '評価タスク',
    },
    NoticeTypeEnum: {
      studyRemind: '学習通知',
      monthlyPlan: '月次計画',
    },
    uploadType: {
      all: 'すべて',
      video: '動画',
      audio: '音声',
      document: 'ドキュメント',
      picture: '画像',
      compressedPackage: 'zipファイル',
      scorm: 'SCORM教材',
      h5: 'H5教材',
    },
    lecturerType: {
      internal: '内部講師',
      external: '外部講師',
    },
    gainCertOrNot: {
      gained: '取得済',
      notGain: '未取得',
    },
    TrainingStatusEnum: {
      notStart: '未開始',
      ongoing: '実施中',
      ended: '完了',
    },
    examStatusEnum: {
      waitTest: '受験待',
      pass: '合格',
      fail: '不合格',
      waitMark: '採点待',
      absent: '欠席',
    },
    monthKeyList: {
      jan: '1月',
      feb: '2月',
      mar: '3月',
      apr: '4月',
      may: '5月',
      jun: '6月',
      jul: '7月',
      aug: '8月',
      sep: '9月',
      oct: '10月',
      nov: '11月',
      dec: '12月',
    },
  },
  VX_certificate: {
    certificateSearch: '証明書検索',
    search: '検索',
    certificateDetails: '証明書詳細',
    certificateNoPlaceHolder: '証明書番号を入力してください',
    pleaseInputcertificateNo: '証明書番号を入力してください',
  },
  VX_development: {
    finishEvaluate: '評価の完成',
    abilityLevel: 'スキルレベル',
    levelDescription: 'レベル説明',
    highPerformanceBehaviorDesc: '高いパフォーマンス行動の説明',
    evaluateUser: '評価対象者',
    abilities: 'スキル項目',
    previousAbility: '前のスキル項目',
    nextAbility: '次のスキル項目',
    evaluateTip: '評価がまだ完了していません。終了しますか',
    evaluate: '評価',
    evaluationReport: '評価レポート',
    evaluatedUser: '評価対象者',
    reportTime: 'レポート時間',
    abilityName: 'スキル項目名',
    positionRequirement: '職位要件',
    superiorEvaluation: '上位',
    subordinateEvaluation: '下位',
    sameLevelEvaluation: '同位',
    finalResult: '最終結果',
    completeEvaluationTask: '評価タスクを完了する',
    completeProgramme: '完了提示案',
    othersEvaluateTip: '他者評価進捗変化ルール: 対象被評価者に関連するすべての評価者の評価が完了した後にのみ、完了評価部分の進捗変化が集計されます。<br>例: 他者評価側面に2人(A、B)がいる場合、Aの評価が完了しても、インターフェイスの他者評価の進捗は変化せず、Bの評価が完了してはじめて進捗変化が集計されます',
    evaluatedUserSearchPlaceholder: '被評価者検索',
    taskStatusPlaceholder: 'タスク状況の選択',
    batchEvaluate: '一括評価',
    evaluationRelation: '評価関係',
    evaluationStatusUpdateTime: '状況更新時間',
    taskStatus: 'タスク状況',
    evaluationStatus: '評価状況',
    evaluated: '評価済',
    notEvaluate: '評価待',
    waitingOthersEvaluate: '他者評価待',
    startEvaluate: '評価開始',
    evaluationTestReport: '評価レポート',
    advanceProgramme: '改善案',
    commonAbility: '汎用スキル',
    positionAbility: '職位スキル',
    positionDemand: '職位要件',
    previous: '前へ',
    next: '次へ',
    abilityExplanation: 'スキル項目説明',
    historyEvaluationTip: 'タスク結果は最新の評価タスクスキル項目結果を基準とします',
    evaluationDimension: '評価次元',
    abilityCount: 'スキル項目数',
    evaluationTime: '評価時間',
    historyEvaluation: '過去の評価',
    criticalTask: 'キータスク',
    totalCount: '合計',
    description: '説明',
    qualificationTipOne: 'キータスクの達成は職位昇進の鍵となる',
    requireCertificate: '必要資格',
    qualificationTipTwo: '必要資格の取得は職位昇進の鍵となる',
    organizationContribution: '組織貢献',
    qualificationTipThree: '組織貢献の取得は職位昇進の鍵となる',
    positionInfomation: '職位情報',
    positionVideoIntroduction: '職位紹介動画',
    postChannelRankName: '職級',
    positionResponsibility: '職責',
    inPositionRequirement: '職務要件',
    studyProgramme: '学習プラン',
    qualificationProgress: '合格進捗',
    completeProgress: '進捗{percent}%完了',
    taskItem: 'タスク項目',
    seekAllCriticalTask: '全キータスクの確認',
    seekAllRequireCertificate: '全必要資格の確認',
    seekAllOrganizationContribution: '全組織貢献の確認',
    improvementSuggestion: '改善意見',
    ability: 'スキル項目',
    importResult: '結果のインポート',
    taskResult: 'タスク結果',
    developmentTip: '現在は兼任の状況にあり、ここで職位を切り替えて異なる状況を確認できます',
    developmentDescriptionTip: '注記: ここでは現在の職位に追加された昇進チャネルが表示されます。現在の職位と昇進職位が同一パス内でない場合は、パス横断昇進として表示されます',
    seek: '確認へ',
    developmentEmptyTip: '現在職位がありません。管理者へ連絡してください',
    positionNotPublished: 'この職位は公開されていません',
    positionNoStudyProgramme: 'この職位には学習プランが設定されていません',
    evaluationAbility: 'スキル評価',
    relateTypeEnum: {
      subEvaluate: '下位評価',
      levelEvaluate: '同位評価',
      superEvaluate: '上位評価',
    },
    postType: {
      prePosition: '前職位',
      curPosition: '現職位',
      proPosition: '昇進職位',
    },
  },
  VX_home: {
    taskNameShow: 'ここでタスク名を表示します',
    designatorNameShow: 'ここでユーザユーザ名を表示します',
    courseNameShow: 'ここで研修名を表示します',
    noLecturer: '講師なし',
    rankList: '順位',
    creditsRank: '単位ランキング',
    integralRank: 'ポイントランキング',
    certificateRank: '証明書ランキング',
    rank: '順位',
    totalCredits: '総単位',
    totalCertificates: '総証明書',
    examNameShow: 'ここで試験名を表示します',
    userName: 'ユーザ名',
    department: '所属部署',
    examScore: '試験成績',
    examRankList: '試験ランキング',
    noAuthTip: '権限なし',
    noAuthTipDetail: 'バックエンドの設定ルールにより、現在の試験のランキングを閲覧する権限がありません',
    ranking: '順位',
    examScores: '試験得点',
    examViewTip: '検索したい試験を選択してください。ランキングルールは試験の具体的な設定に従って表示されます',
    score: '得点',
    pageCountTip: '計{count}ページ',
    dataCountTip: '{count}件データ',
    studyNameShow: 'ここでタスク名を表示します',
    learnTaskNameShow: 'ここで学習タスク名を表示します',
    name: 'ユーザ名',
    investigateTip: 'このアンケートは匿名で行われますので、ご安心いただきご回答ください。ご協力ありがとうございます。',
    gradeDescriptionPlaceholder: 'レーティング説明を入力してください',
    mustInput: '（必須）',
    phoneNumPlaceholder: '電話番号を入力してください',
    phoneNumInputTip: '正しい電話番号を入力してください',
    enableSmsVerifyPlaceholder: '認証コードを入力してください',
    commitSuccessTip: '提出に成功しました。このアンケートにご協力いただきありがとうございます。',
    viewResult: '集計結果を確認する＞',
    viewOther: 'その他を確認する',
    phoneNumberFalseTip: '{key}番目の質問の電話番号が正しくありません。再度入力してください',
    answerInputTip: '{index}番目の質問の答えを選択し、アンケートを提出してください',
    otherInputTip: '{index}番目の質問の「その他」の詳細内容を入力し、アンケートを提出してください',
    inputTip: '{index}番目の質問の答えを入力し、アンケートを提出してください',
    minLengthInputTip: '{index}番目の質問の答えは最小{length}文字以上必要です',
    phoneNumberTip: '{index}番目の質問の電話番号を入力し、アンケートを提出してください',
    enableSmsVerifyTip: '{index}番目の質問の SMS 認証コードを入力し、アンケートを提出してください',
    gradeTip: '{index}番目の質問のレーティングを選択し、アンケートを提出してください',
    gradeDescriptionTip: '{index}番目の質問のレーティング説明を記入し、アンケートを提出してください',
    writeCount: '現在までに{count}名のユーザがこのアンケートに参加し、以下にその統計結果を示します:',
    statisticsResultTip: '択一式問題、複数選択問題、レーティングに対して集計可能です',
    questionOption: '質問項目',
    totalCount: '合計数',
    percent: '割合',
    gradeOption: 'レーティング項目',
    optionCharacter: '項目文言',
    scores: '点数',
    writeCounts: '回答者数',
    noStatistics: '集計内容なし',
    noLecturerName: '講師名未定',
    lecturerField: 'ここで講師の得意分野が表示されます',
    integralMall: 'ポイントショップ',
    toIntegralMall: 'ポイントショップへ商品を交換する',
    toExchange: '交換へ',
  },
  VX_knowledge: {
    playErr: '再生エラー',
    playErrTip: '再生が異常です。{count}秒後に続けて再生できます。今回の学習記録は無効となります',
    noNextChapterTip: '次の章がありません',
    noNextSectionTip: '次の節がありません',
    autoPlay: '次の節を自動で再生します',
    videoNotUpdate: 'この動画は更新されていません',
    videoWatchTip: '購入してから視聴してください',
    enrollTip: '先にログインして申請してください',
    lecturer: '講師',
    attendenceArrangement: '出席管理',
    userNamePlaceholder: '名前で検索',
    deptPlaceholder: '部署を選択してください',
    pictureUserName: '顔写真/名前',
    empNo: 'ID',
    post: '職位',
    email: 'メールアドレス',
    joinTime: '参加時間',
    trainingProgress: '研修全体の進捗状況',
    signInTime: '出席時間',
    signOutTime: '退席時間',
    traningDuration: 'オフライン研修期間',
    assessScore: '評価成績',
    assessComment: '評価コメント',
    assessAttachmentCount: 'レーティング結果の添付ファイル',
    assessUserName: '成績入力者',
    assessAttachment: 'レーティングの添付ファイル',
    noResource: '学習リソースがありません',
    chapterCount: '第{count}章',
    relatedLecturer: '講師',
    myProgress: 'My進捗',
    progressFinished: '進捗{percent}完了',
    examPassCount: '試験に合格{count}回',
    practicePassCount: '実技宿題{count}回通過',
    applyNotPass: '申請が却下されました。理由を確認してください',
    reApply: '再申請',
    learned: '{count}名が学習完了しました',
    rejectReason: '却下理由',
    courseLoadingFailTip: '研修情報の読み込みに失敗しました',
    dataLoadingFailTip: 'データの読み込みに失敗しました。後でもう一度試してください。',
    gradeGroupTitle: '星評価（{count}）',
    comprehensiveGrade: '総合レーティング',
    gradeTime: 'レーティング時間',
    auditScore: '得点',
    waitingGrade: '講師レーティングを待っています',
    morning: '午前',
    afternoon: '午後',
    attendanceManager: '出席責任者',
    notSignIn: '未出席',
    notSignOut: '未退席',
    courseExpired: '研修が期限切れました',
    myGrade: 'Myレーティング',
    daysAgo: '{day}日前',
    hoursAgo: '{hour}時間前',
    minutesAgo: '{minute}分前',
    taskExpired: 'タスクが期限切れました',
    evaluatedLecturer: '評価した講師',
    evaluateLecturer: '講師を評価する',
    evaluatedCourse: '評価した研修',
    evaluateCourse: '研修を評価する',
    from: '情報源',
    taskContent: '宿題内容',
    count: '{count}件',
    evaluationTip: '評価いただきありがとうございました！',
    signStatus: {
      normal: '通常',
      late: '遅刻',
      leaveEarlier: '早退',
      absent: '欠勤',
      askingForLeave: '欠席',
    },
    LiveStatusEnum: {
      notLive: 'ライブ待',
      enterLive: 'ライブに入る',
      ended: 'ライブ終了',
      replay: 'リプレイ再生',
    },
  },
  VX_utils: {
    yearsAgo: '{year}年前',
    monthsAgo: '{month}ヶ月前',
    weeksAgo: '{week}週間前',
    daysAgo: '{day}日前',
    hoursAgo: '{hour}時間前',
    minutesAgo: '{minute}分前',
    justNow: 'ただいま',
    oneDayAgo: '1日前',
    month: '月',
    date: '日',
    hours: '時',
    minutes: '分',
    hour: '時間',
    day: '日',
    second: '秒',
    minute: '分',
  },
  VX_company: {
    companySelect: '企業選択',
    myCompany: 'My企業',
    companyCreateTip: '最大10社作成できます',
    registerCompany: '企業登録',
    enterCompany: '企業に入る',
    basicInfoInput: '基本情報入力',
    companyCreationSuccessTip: 'おめでとうございます！企業の作成に成功しましｔ．7日間無料試用できます',
    backToHome: 'ホームページに戻る',
  },
  VX_header: {
    center: '機構センター',
    keywordInputPlaceholder: 'キーワードを検索してください',
    targetNum: {
      1: 'ホームページ',
      2: 'ライブラリ',
      3: '講師センター',
      4: 'ポイントショップ',
      5: '人材開発',
      6: '個人センター',
    },
    languageTypeEnum: {
      zh: '中文',
      zhhk: '中文繁體',
      en: 'English',
      ja: '日本語',
    },
  },
  VX_login: {
    agreement: '利用規約',
    refuse: '拒否',
    agree: '同意',
    contactManagerTip: '管理者に連絡してください',
    pleaseInputLoginPassword: '8〜30文字の英数字、記号の組み合わせのものを設定してください',
    sendCodeSuccess: '認証コード送信に成功しました',
    reGet: '再取得',
    noAuth: '権限なし',
  },
  VX_integral: {
    exchangeCondition: '交換条件',
    conditionTip: '以下の内容を学習してから交換してください',
    toLearn: '学習へ',
    studyWarningTipOne: '内容は非公開されたましたので、学習できません。管理者に連絡してください',
    studyWarningTipTwo: '権限がありません。管理者に連絡してください',
    consumeIntegral: '消費必要：<span><b>{count}</b>ポイント</span>',
    alreadyExchangeCount: '交換済{count}',
    deadline: '締め切り時間',
    finishSpecificContent: '学習内容指定',
    afterExchangingNotice: '交換後注意事項',
    exchangeTip: '交換後は返品・交換はできません',
    surplusStock: '在庫',
    piece: '件',
    exchangeLimit: '（交換制限{count}件）',
    immediateExchange: '今すぐ交換',
    noStock: '交換完了',
    confirmOrder: '注文確認',
    stockCount: '在庫{count}件',
    consumption: '消費必要',
    exchangeSuccess: '交換に成功しました',
    goodsDescription: '商品説明',
    rankType: 'ソート方法',
    integralRange: 'ポイント空間',
    stockStatus: '在庫状態',
    inStock: '在庫あり',
    integralConversionConditionTypeEnum: {
      course: '研修',
      studyProject: 'コース',
      studyTask: '学習タスク',
    },
  },
  VX_lecturer: {
    inputScore: '試験点数登録',
    notInput: '未登録',
    hasInput: '登録済',
    getScore: '得点：',
    pass: '合格',
    assessComment: 'コメント：',
    uploadAttachment: '添付ファイルアップロード：',
    assessTip: '添付ファイルはユーザに表示されます。ドキュメント、圧縮ファイルをアップロードできます。最大5個まで添付できます',
    inputScorePlaceholder: '得点を入力してください',
    uploadData: '資料アップロード',
    attachmentTip: '画像、ドキュメントをアップロードできます。最大10個添付できます、サイズは2GB以内でアップロードできます',
    uploadAttachmentPlaceholder: '添付ファイルを選択してアップロードしてください',
    evaluateUserCount: 'ユーザを評価する({count})',
    notEvaluate: '評価待',
    evaluated: '評価済',
    noLabel: 'タグがありません',
    courseTaughtCount: '{count}の授業済の研修',
    questionnaireCount: 'アンケート({count})',
    courseCount: '研修数',
    studyProjectCount: 'コース数',
    lecturerIntroduction: '講師プロフィール',
    offlineCourse: 'オフライン研修',
    notStartCount: '未実施({count})',
    liveCount: '実施中({count})',
    endedCount: '終了({count})',
    address: '場所',
    purpose: '目的',
    assessScore: '評点',
    evaluateUser: 'ユーザを評価する',
    searchByName: '{name}名で検索',
    lecturerLevel: '講師レベル',
    searchByLecturerPlaceholder: '講師名で検索',
    courseTypeEnum: {
      onlineCourse: 'eラーニング',
      pictureCourse: 'テキスト',
      liveCourse: 'ライブ',
      studyProject: 'コース',
      offlineCourse: 'オフライン研修',
    }
  },
  VX_common: {
    callCustomerService: 'カスタマーサポート',
    customerServiceQQ: 'カスタマーサポートQQ',
    link: 'リンク',
    websiteNav: 'ウェブサイトメニュー',
  },
  VX_register: {
    commitApplication: '申請提出',
    pleaseInputUserName: 'ユーザ名を入力してください',
    pleaseInputPhone: '電話番号を入力してください',
    pleaseInputCompanyName: '企業名を入力してください',
    pleaseSelectEntIndustry: '業界を選択してください',
    pleaseSelectEntScale: '会社の規模を選択してください',
    pleaseInputCity: '都市を入力してください',
    pleaseInputProvince: '都道府県を入力してください',
  },
  VX_component: {
    faceRecognitionAuth: '顔認証',
    clickSign: '打刻',
    remainSeconds: '残り {second} 秒',
    faceRecognitionTipOne: '1.あなたの本名と身分証明書番号が正しいことを確認してください。',
    faceRecognitionTipTwo: '2.カメラが使用可能か、サイトの使用を許可しているかを確認してください。使用できない場合は、アプリまたはウェブサイトで顔認証を行ってください。',
    faceRecognitionTipThree: '3.残りの顔認証回数は <span>{remainCount}</span> 回です。現在の失敗回数は <span>{currentErrorCount}</span> 回で、これ以上失敗するとテストや学習に進めなくなります。ご確認ください。',
    verifyIdentity: '本人確認',
    validateSuccess: '認証成功',
    signSuccess: '打刻成功',
    modifyInfo: '本名情報を変更する',
    realName: '本名',
    realNameInputPlaceholder: '本名を入力してください',
    idCard: '身分証明書',
    idCardInputPlaceholder: '身分証明書番号を入力してください',
    signTip: '撮影しています! 頭を動かしたり、カメラから外さないでください',
    startAuth: '認証開始',
    resetTip: '氏名と身分証明書番号は正しいでしょうか?',
    signConfirmTips: '<p style="text-align: left;margin-bottom: 30px">現在、顔認証が必要です。氏名と身分証番号を入力してから {typeContent} にアクセスしてください。</p>',
    study: '学習',
    confirmTip: '顔認証アラート',
    confirmInput: '入力へ',
    recognitionNoTimes: '顔認証の試行回数が上限に達したため、{typeContent}に入ることができません。管理者に連絡してください。',
    errorTimesLimit: '本日の顔認証エラー回数が上限に達したため、{typeContent}に入ることができません。管理者に連絡してください。',
    confirm: '了解',
    signVideoUploadTip: '打刻動画をアップロードしています。閉じないでください',
    close: '閉じる ({second})',
    reAuth: '再認証',
    confirmIdCardAndName: '氏名と身分証明書番号は正しいでしょうか?',
    checkingCamera: 'カメラを検出しています...',
    validating: '認証中です。このページを離れないでください...',
    faceRecognitionOvertime: '顔認証がタイムアウトしました。',
    cameraAbnormal: 'カメラに問題があります...',
    cameraAbnormalTip: 'カメラが使用可能か、サイトの使用を許可しているかを確認してください。カメラがない場合はアプリやウェブサイトで顔認証を行ってください',
    errorTip: 'エラーメッセージ',
    relogin: '再ログインしてカメラを許可する',
    uploadPictureFailure: '画像のアップロードエラー。後ほどもう一度お試しください。',
    unfold: '開く',
    fold: '閉じる',
  },
  VX_error: {
    serviceError: 'サーバーエラー',
    tipOne: '申し訳ございません。サーバーにエラーが発生しました',
    tipTwo: 'このページにバグがある可能性があります。管理者に連絡してください',
    tipThree: '戻る',
  }
}

// 生产环境
let config = {
  baseUrl: 'http://localhost/gateway', // 网关地址，一般使用内网地址
  tokenName: 'yqx_token'
}

if (process.env.NODE_ENV === 'development') {
  // 开发环境
  config = {
    ...config,
    baseUrl: 'https://lms-dev.learningpark.cn/gateway' // 网关地址
  }
}
export default config

import zh from './zh-cn'
import zhhk from './zh-hk'
import en from './en'
import ja from './ja' // 添加切换语言按钮

// 加载element-ui语言包

import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import zhhkLocale from 'element-ui/lib/locale/lang/zh-TW'
import jaLocale from 'element-ui/lib/locale/lang/ja' // 添加切换语言按钮

const I18N = {
  locales: [{
    code: 'en',
    iso: 'en-US',
    name: 'English'
  },
  {
    code: 'zh',
    iso: 'zh-ZH',
    name: '中文'
  },
  {
    code: 'zhhk',
    iso: 'zh-HK',
    name: '中文繁體'
  },
  // 添加切换语言按钮
  {
    code: 'ja',
    iso: 'ja',
    name: '日本語'
  }
  ],
  defaultLocale: 'zh',
  vueI18n: {
    fallbackLocale: 'zh',
    messages: {
      en: {
        ...enLocale,
        ...en
      },
      zh: {
        ...zhLocale,
        ...zh
      },
      zhhk: {
        ...zhhkLocale,
        ...zhhk
      },
      // 添加切换语言按钮
      ja: {
        ...jaLocale,
        ...ja
      }
    }
  }
}

export default I18N
